<template>
    <div
        v-click-outside="handleBackClick(false)"
        class="d-flex flex-column ma-2"
        style="height: 100%"
    >
        <progress-bar
            title="Hochladen"
            :show-progress="showAttachmentUploadProgress"
            :progress="attachmentUploadProgress"
            :abort-progress="fileXmlHttpRequest ? () => { fileXmlHttpRequest.abort() }: () => {}"
            aria-hidden="true"
        />

        <div
            class="greyBox d-flex flex-column align-center"
            style="height: 100%; min-width: 266px"
        >
            <vue-custom-scrollbar
                class="scroll-area"
                :settings="settings"
                style="width: 100%"
                :style="getAvatarPickerOpen ? 'min-height: 80vh' : ''"
            >
                <div
                    class="d-flex justify-space-between align-center px-2"
                    style="width: 100%"
                >
                    <p><b>Profileinstellungen</b></p>
                    <div class="d-flex flex-row justify-space-around">
                        <v-btn
                            icon
                            elevation="0"
                            aria-hidden="true"
                            @click="reloadPage"
                        >
                            <img
                                :src="aktualisierenIcon"
                                alt="Aktualisieren"
                                class="icon "
                            >
                        </v-btn>
                        <v-btn
                            icon
                            elevation="0"
                            aria-hidden="true"
                            @click="handleBackClick(true)"
                        >
                            <img
                                :src="schließenIcon"
                                class="icon"
                                alt="Scließen"
                            >
                        </v-btn>
                    </div>
                </div>

                <!-- Select child if role is parent and account has more than one child -->
                <div
                    v-if="accountRole === 'parent' && windowWidth < 900"
                    class="d-flex flex-column align-start align-self-start px-2"
                >
                    <div class="d-flex align-center">
                        <div
                            v-if="children.length > 1"
                            class="d-flex align-center"
                        >
                            <p>Eltern von</p>

                            <v-select
                                v-model="currentChild"
                                :items="children"
                                :item-text="item => item.name + '. ' + Array.from(item.lastName)[0]"
                                item-value="_id"
                                outlined
                                dense
                                return-object
                                hide-details="true"
                                class="inputField ml-2"
                                style="max-width: 150px;"
                                @change="() => { this.$emit('currentChildChange', this.currentChild) }"
                            />
                        </div>

                        <p v-else>
                            {{ 'Eltern von ' + children[0].name }}
                        </p>
                    </div>
                </div>

                <div class="d-flex flex-column align-start align-self-start px-2">
                    <div class="d-flex align-center">
                        <AvatarCreator
                            @upload="setSaving"
                            @finishedUpload="resetSaving"
                        />
                        <div class="ml-4">
                            <p>
                                <b>
                                    {{ displayName }}
                                </b>
                            </p>
                            <div>
                                <span
                                    v-for="(groupName,index) in groupnames"
                                    :key="index"
                                    style="width: 100%"
                                >
                                    {{ groupnames.length > 1 ?
                                        index !== groupnames.length - 1 ?
                                            groupName.name + ', '
                                            : groupName.name
                                        : groupName.name
                                    }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- BBB buttons -->
                <div
                    v-if="windowWidth < 900"
                    class="mt-2 px-2"
                >
                    <join-lehrerzimmer-button v-if="accountRole === 'teacher' || accountRole === 'maintainer'" />

                    <join-klassenraum-button v-if="accountRole === 'teacher' || accountRole === 'pupil'" />

                    <join-sprechzimmer-button v-if="accountRole === 'teacher' || accountRole === 'parent'" />

                    <join-external-button v-if="accountRole === 'teacher' || accountRole === 'maintainer'" />
                </div>
                <div
                    v-if="(userrole.translationAllowed && accountRole === 'pupil') || accountRole === 'parent'"
                    class="d-flex flex-column align-start align-self-start px-2 mt-2"
                >
                    <p><b>Sprache</b></p>

                    <v-select
                        v-model="selectedLanguage"
                        :items="languages"
                        item-text="text"
                        item-value="countryCode"
                        outlined
                        dense
                        hide-details
                        :menu-props="{ bottom: true, offsetY: true, closeOnContentClick: true }"
                        class="inputField mb-2"
                        style="width: 100%"
                        @click="setAvatarPickerOpen(true)"
                        @change="changeLanguage()"
                        @focus="setAvatarPickerOpen(true)"
                        @focusout="setAvatarPickerOpen(false)"
                    >
                        <template v-slot:selection="{ item }">
                            <img
                                :src="item.icon"
                                class="icon mr-2"
                                :alt="item.alt"
                            >
                            {{ item.text }}
                        </template>

                        <template v-slot:item="{ item, on }">
                            <v-list-item v-on="on">
                                <img
                                    :src="item.icon"
                                    class="icon mr-2"
                                    :alt="item.alt"
                                >
                                {{ item.text }}
                                <!-- <v-icon
                                    v-if="item.text === 'Arabisch'"
                                    class="ml-4 icon20"
                                >
                                    mdi-google-translate
                                </v-icon> -->
                            </v-list-item>
                        </template>
                    </v-select>
                </div>

                <div class="px-2 pt-2">
                    <v-divider
                        v-if="accountRole != 'maintainer'"
                        class="mb-2"
                    />
                    <button
                        class="d-flex justify-space-between"
                        style="cursor: pointer; width: 100%"
                        @click="showHelpers = !showHelpers"
                    >
                        <div class="d-flex">
                            <img
                                :src="helpIcon"
                                class="icon mr-2"
                                alt="Hilfen"
                            >
                            <p class="font-weight-bold">
                                Hilfen
                            </p>
                        </div>
                        <v-icon v-if="showHelpers">
                            mdi-chevron-up
                        </v-icon>
                        <v-icon v-else>
                            mdi-chevron-down
                        </v-icon>
                    </button>
                    <div v-if="showHelpers && (accountRole === 'pupil' || accountRole === 'parent')">
                        <div
                            class="d-flex-column justify-space-between"
                            style="width: 100%"
                        >
                            <div
                                v-for="(accessibility, _id) in accessibilities.filter((el) => accountRole === 'pupil' || (accountRole === 'parent' && el.text !== 'Einfach'))"
                                :key="_id"
                                class="d-flex justify-space-between align-center mb-1"
                            >
                                <div class="d-flex align-center">
                                    <span
                                        class="text-capitalize"
                                        :style="accessibility.text === 'Einfach' ? 'font-weight: bold' : ''"
                                    >{{ accessibility.text }}</span>

                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <img
                                                v-if="accessibility.explanation"
                                                :src="infoIcon"
                                                class="icon pl-1"
                                                alt="Erklärung"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                        </template>
                                        <!-- Disable v-html xss checking accessibility.explanation is provided only by developers -->
                                        <!-- eslint-disable vue/no-v-html -->
                                        <p
                                            style="max-width: 250px"
                                            v-html="accessibility.explanation"
                                        />
                                    </v-tooltip>
                                </div>

                                <v-switch
                                    v-model="accessibility.value"
                                    inset
                                    hide-details
                                    :disabled="accessibility.text === 'Screenreader' && !accessibilities[0].value"
                                    color="success"
                                    class="mt-0"
                                    @change="toggleA11y(accessibility)"
                                />
                            </div>

                            <!-- Additional accessibilities: keyboard, magnifier -->
                            <div class="d-flex justify-space-between align-center mb-1">
                                <span class="text-capitalize"><span style="text-decoration: underline black">L</span>upe</span>

                                <v-switch
                                    :input-value="magnifier"
                                    inset
                                    hide-details
                                    color="success"
                                    class="mt-0"
                                    @change="() => { this.$emit('closeDialog'); this.toggleMagnifier() }"
                                />
                            </div>


                            <div class="d-flex justify-space-between align-center mb-1">
                                <span class="text-capitalize"><span style="text-decoration: underline black">T</span>astatursteuerung</span>

                                <v-switch
                                    :input-value="keyboard"
                                    inset
                                    hide-details
                                    color="success"
                                    class="mt-0"
                                    @change="() => { this.$emit('closeDialog'); this.toggleKeyboard() }"
                                />
                            </div>
                        </div>
                        <div
                            class="d-flex align-center mt-2 mb-1"
                        >
                            <p class="font-weight-bold">
                                Video Tutorials anzeigen
                            </p>

                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <img
                                        :src="infoIcon"
                                        class="icon pl-1"
                                        alt="Erklärung"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                </template>
                                <p style="max-width: 250px">
                                    Einstellung, ob Video-Tutorials angezeigt werden sollen
                                </p>
                            </v-tooltip>
                        </div>

                        <div
                            v-for="(videoTutorial, index) in videoTutorialsOfMyRole"
                            :key="index"
                            class="d-flex justify-space-between"
                        >
                            <p>{{ videoTutorial.title }}</p>

                            <v-switch
                                v-model="videoTutorial.seeAgain"
                                style="margin-top: 0; padding-top: 0; margin-bottom:1.5%"
                                inset
                                hide-details
                                color="success"
                                @change="changeVideoTutorialSeeAgainState(videoTutorial.filename, videoTutorial.seeAgain)"
                            />
                        </div>
                    </div>
                    <div v-if="showHelpers && (accountRole === 'teacher' ||accountRole === 'maintainer')">
                        <!-- Simple Editor Stuff >
                        <p class="font-weight-bold mt-2">
                            Editor
                        </p>

                        <div class="d-flex justify-space-between pa-0 ma-0">
                            <p aria-hidden="true">
                                Einfacher Editor-Modus für Lehrer
                            </p>
                            <v-switch
                                v-model="simpleEditorValue"
                                inset
                                hide-details
                                aria-label="Einfacher Editor-Modus für Lehrer"
                                color="success"
                                class="ma-0 pa-0 pl-1 mb-1"
                                @change="toggleSimpleEditor"
                            />
                        </div-->
                        <div class="d-flex align-center mt-2 mb-1">
                            <p class="font-weight-bold">
                                Video Tutorials anzeigen
                            </p>

                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <img
                                        :src="infoIcon"
                                        class="icon pl-1"
                                        alt="Erklärung"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                </template>
                                <p style="max-width: 250px">
                                    Hier kann eingestellt werden welche Tutorials angezeigt werden sollen.
                                </p>
                            </v-tooltip>
                        </div>

                        <div
                            v-for="(videoTutorial, index) in videoTutorialsOfMyRole"
                            :key="index"
                            class="d-flex justify-space-between"
                        >
                            <p>{{ videoTutorial.title }}</p>

                            <v-switch
                                v-model="videoTutorial.seeAgain"
                                style="margin-top: 0; padding-top: 0; margin-bottom:1.5%"
                                inset
                                hide-details
                                color="success"
                                @change="changeVideoTutorialSeeAgainState(videoTutorial.filename, videoTutorial.seeAgain)"
                            />
                        </div>
                    </div>
                </div>
                <div
                    v-if="account.role === 'maintainer'"
                    class="px-2"
                >
                    <div>
                        <v-divider
                            class="my-2"
                        />
                        <button
                            class="d-flex justify-space-between"
                            style="cursor:pointer; width: 100%"
                            @click="()=>{showWidgetMaintainence = !showWidgetMaintainence; saveDeactivatedFeatures();}"
                        >
                            <div class="d-flex mb-2">
                                <img
                                    :src="appIcon"
                                    class="icon mr-2 iconToDunkelgrau"
                                    alt="Apps"
                                >
                                <p class="font-weight-bold">
                                    Apps
                                </p>
                                <v-tooltip
                                    allow-overflow
                                    right
                                    max-width="400px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <img
                                            :src="infoIcon"
                                            class="icon pl-1"
                                            alt="Erklärung"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                    </template>
                                    <span>Wenn Sie einige Funktionen in eklara nicht nutzen möchten, können Sie diese hier schulweit deaktivieren. Die Änderungen werden nach erneutem Laden der Seite aktiv.</span>
                                </v-tooltip>
                            </div>
                            <v-icon v-if="showWidgetMaintainence">
                                mdi-chevron-up
                            </v-icon>
                            <v-icon v-else>
                                mdi-chevron-down
                            </v-icon>
                        </button>
                        <div
                            v-if="showWidgetMaintainence"
                            v-click-outside="saveDeactivatedFeatures"
                        >
                            <div
                                class="d-flex justify-space-between mt-2"
                            >
                                <div class="d-flex flex-row justify-space-around">
                                    <img
                                        :src="brettIcon"
                                        style="display: block; height: 19px; margin-top: 3px;  margin-right: 5px"
                                    >
                                    <p style="font-size:larger">
                                        Schwarzes Brett
                                    </p>
                                </div>
                                <v-switch
                                    v-model="deactivatedBlackboard"
                                    inset
                                    hide-details
                                    color="success"
                                    class="mt-0"
                                />
                            </div>
                            <div
                                class="d-flex justify-space-between mt-2"
                            >
                                <div class="d-flex flex-row justify-space-around">
                                    <img
                                        :src="clockIcon"
                                        style="display: block; height: 19px; margin-top: 3px; margin-right: 5px"
                                    >
                                    <p style="font-size:larger">
                                        Stundenplan
                                    </p>
                                </div>
                                <v-switch
                                    v-model="deactivatedPlan"
                                    inset
                                    hide-details
                                    color="success"
                                    class="mt-0"    
                                />
                            </div>
                            <div
                                class="d-flex justify-space-between mt-2"
                            >
                                <div class="d-flex flex-row justify-space-around">
                                    <img
                                        :src="chatIcon"
                                        style="display: block; height: 19px; color: margin-top: 3px; margin-right: 5px"
                                    >
                                    <p style="font-size:larger">
                                        Chat
                                    </p>
                                </div>
                                <v-switch
                                    v-model="deactivatedChat"
                                    inset
                                    hide-details
                                    color="success"
                                    class="mt-0"    
                                />
                            </div>                            
                            <div
                                class="d-flex justify-space-between mt-2"
                            >
                                <div class="d-flex flex-row justify-space-around">
                                    <img
                                        :src="mailIcon"
                                        style="display: block; height: 19px; margin-top: 3px; margin-right: 5px"
                                    >
                                    <p style="font-size:larger">
                                        Elternbrief
                                    </p>
                                </div>
                                <v-switch
                                    v-model="deactivatedMessages"
                                    inset
                                    hide-details
                                    color="success"
                                    class="mt-0"    
                                />
                            </div>                            
                            <div
                                class="d-flex justify-space-between mt-2"
                            >
                                <div class="d-flex flex-row justify-space-around">
                                    <img
                                        :src="fileIcon"
                                        style="display: block; height: 19px; margin-top: 3px; margin-right: 5px"
                                        class="iconToDunkelgrau"
                                    >
                                    <p style="font-size:larger">
                                        Dateien
                                    </p>
                                </div>
                                <v-switch
                                    v-model="deactivatedFiles"
                                    inset
                                    hide-details
                                    color="success"
                                    class="mt-0"    
                                />
                            </div>                            
                            <div
                                class="d-flex justify-space-between mt-2"
                            >
                                <div
                                    class="d-flex flex-row justify-space-around"
                                    @click="showAppsSection = !showAppsSection"
                                >
                                    <v-icon v-if="showAppsSection">
                                        mdi-chevron-up
                                    </v-icon>
                                    <v-icon v-else>
                                        mdi-chevron-down
                                    </v-icon>
                                    <p style="font-size:larger">
                                        Apps
                                    </p>
                                </div>
                                <v-switch
                                    v-model="deactivatedApps"
                                    inset
                                    hide-details
                                    color="success"
                                    class="mt-0"    
                                />
                            </div>
                            <div
                                v-if="showAppsSection" 
                                class="d-flex flex-column justify-space-around ml-4 "
                            >                            
                                <div
                                    class="d-flex justify-space-between mt-2"
                                >
                                    <div class="d-flex flex-row justify-space-around">
                                        <img
                                            :src="bookIcon"
                                            style="display: block; height: 19px; margin-top: 3px; margin-right: 5px"
                                            class="iconToDunkelgrau"
                                        >
                                        <p style="font-size:16px">
                                            Klassenbuch
                                        </p>
                                    </div>
                                    <v-switch
                                        v-model="deactivatedDiary"
                                        inset
                                        hide-details
                                        color="success"
                                        class="mt-0"    
                                    />
                                </div>
                                <div
                                    class="d-flex justify-space-between mt-2"
                                >
                                    <div class="d-flex flex-row justify-space-around">
                                        <img
                                            :src="chatIcon"
                                            style="display: block; height: 19px; margin-top: 3px; margin-right: 5px"
                                        >
                                        <p style="font-size:16px;">
                                            Kalender
                                        </p>
                                    </div>
                                    <v-switch
                                        v-model="deactivatedCalendar"
                                        inset
                                        hide-details
                                        color="success"
                                        class="mt-0"    
                                    />
                                </div>  
                                <div
                                    class="d-flex justify-space-between my-2"
                                >
                                    <div class="d-flex flex-row justify-space-around">
                                        <img
                                            :src="pinIcon"
                                            style="display: block; height: 19px; color: var(--v-dunkelgrau-base);margin-top: 3px; margin-right: 5px"
                                        >
                                        <p style="font-size:16px">
                                            Pinnwand
                                        </p>
                                    </div>
                                    <v-switch
                                        v-model="deactivatedPinboard"
                                        inset
                                        hide-details
                                        color="success"
                                        class="mt-0"    
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--#endregion -->
                </div>
                <div
                    v-if="account.role === 'maintainer'"
                    class="px-2"
                >
                    <!--#region Briefkopf -->
                    <div>
                        <v-divider
                            :class="showInitBadge < 1 ? 'mb-2':''"
                        />
                        <v-badge
                            v-if="showInitBadge > 0"
                            :content="showInitBadge"
                            overlap
                            left
                            color="#ff0000"
                            offset-y="15"
                            style="z-index: 9; width:100%; cursor: pointer;"
                            class="mt-0 pt-0"
                        />
                        <button
                            class="d-flex justify-space-between"
                            style="cursor:pointer; width: 100%"
                            @click="showBriefkopf = !showBriefkopf; saveMaintainerProfile"
                        >
                            <div class="d-flex mb-2">
                                <img
                                    :src="briefIcon"
                                    class="icon mr-2"
                                    alt="Brief"
                                >
                                <p class="font-weight-bold">
                                    Schulangaben
                                </p>
                            </div>
                            <v-icon v-if="showBriefkopf">
                                mdi-chevron-up
                            </v-icon>
                            <v-icon v-else>
                                mdi-chevron-down
                            </v-icon>
                        </button>
                        <div
                            v-if="showBriefkopf"
                            v-click-outside="saveMaintainerProfile"
                        >
                            <p class="mb-4">
                                Füllen Sie die folgenden Felder aus, um den Briefkopf des Elternbrief-Exports anzupassen
                            </p>

                            <p :class="schoolName ==='' ? 'errorText' : ''">
                                Name der Schule
                            </p>
                            <v-text-field
                                v-model="schoolName"
                                outlined
                                dense
                                hide-details
                                class="inputField mb-2"
                                :error="schoolName ===''"
                            />
                            <p :class="schoolStreet ==='' ? 'errorText' : ''">
                                Straße und Nummer
                            </p>
                            <v-text-field
                                v-model="schoolStreet"
                                outlined
                                dense
                                hide-details
                                class="inputField mb-2"
                                :error="schoolStreet ===''"
                            />
                            <div
                                class="d-flex"
                                style="min-width: 100%"
                            >
                                <div style="width: 25%">
                                    <p
                                        :class="schoolPostal ==='' ? 'errorText' : ''"
                                    >
                                        PLZ
                                    </p>
                                    <v-text-field
                                        v-model="schoolPostal"
                                        outlined
                                        dense
                                        hide-details
                                        class="inputField mb-2"
                                        :error="schoolPostal ===''"
                                    />
                                </div>

                                <div
                                    class="ml-2"
                                    style="width: 75%"
                                >
                                    <p
                                        :class="schoolCity ==='' ? 'errorText' : ''"
                                    >
                                        Ort
                                    </p>
                                    <v-text-field
                                        v-model="schoolCity"
                                        outlined
                                        dense
                                        hide-details
                                        class="inputField mb-2"
                                        :error="schoolCity ===''"
                                    />
                                </div>
                            </div>

                            <hr class="mb-2">
                            <p :class="schoolEmail ==='' ? 'errorText' : ''">
                                E-Mail
                            </p>
                            <v-text-field
                                v-model="schoolEmail"
                                outlined
                                dense
                                hide-details
                                class="inputField mb-2"
                                :error="schoolEmail === ''"
                            />

                            <p
                                :class="schoolPhone ==='' ? 'errorText' : ''"
                            >
                                Telefon
                            </p>
                            <v-text-field
                                v-model="schoolPhone"
                                outlined
                                dense
                                hide-details
                                class="inputField mb-2"
                                :error="schoolPhone === ''"
                            />
                            <p
                                :class="principal ==='' ? 'errorText' : ''"
                            >
                                Schulleiter
                            </p>
                            <v-text-field
                                v-model="principal"
                                outlined
                                dense
                                hide-details
                                class="inputField mb-2"
                                :error="principal ===''"
                            />
                            <p :class="schoolDataSecurityOfficer ==='' ? 'errorText' : ''">
                                Kontakt Datenschutzbehörde
                            </p>
                            <v-text-field
                                v-model="schoolDataSecurityOfficer"
                                outlined
                                dense
                                hide-details
                                class="inputField mb-2"
                                :error="schoolDataSecurityOfficer === ''"
                            />
                            <!-- <p>Zuständige Datenschutzbehörde</p>
                            <v-text-field
                                v-model="generalDataSecurityOfficer"
                                outlined
                                dense
                                hide-details
                                class="inputField mb-2"
                            /> -->

                            <hr class="mb-2">

                            <div class="d-flex align-center justify-space-between">
                                <p>Logo hochladen (png oder jpg)</p>

                                <v-btn
                                    class="actionBtn mr-2"
                                    x-small
                                    elevation="0"
                                    @click="clickElement('fileInput')"
                                >
                                    <img
                                        :src="uploadIcon"
                                        class="icon"
                                        alt="Logo hochladen"
                                    >
                                </v-btn>
                            </div>

                            <input
                                id="fileInput"
                                ref="fileInput"
                                type="file"
                                accept="image/png, image/jpeg"
                                hidden
                                @change="fileInputChange"
                            >

                            <div class="d-flex-column">
                                <img
                                    v-if="logoToPreview"
                                    :src="logoToPreview"
                                    style="max-height: 200px !important"
                                    alt="Vorschau aktuelles Logo"
                                >
                                <p><small>Aktuelles Logo</small></p>
                            </div>

                            <div
                                v-if="logoPreview"
                                class="d-flex-column"
                            >
                                <v-divider class="mt-2" />
                                <img
                                    :src="logoPreview"
                                    style="max-height: 200px !important"
                                    alt="Vorschau neues Logo"
                                >
                                <p><small>Neues Logo</small></p>
                            </div>
                        </div>
                    </div>
                    <!--#endregion -->
                </div>
                <!--#region Notifications -->
                <div class="px-2 ">
                    <div v-if="account.role !== 'pupil'">
                        <v-divider :class="showEmailBadge ? '' : 'mb-2'" />
                        <v-badge
                            v-if="showEmailBadge"
                            content="2"
                            overlap
                            left
                            color="#3ba934"
                            style="z-index: 9; width:100%"
                            class="insetBadge"
                        />

                        <button
                            class="d-flex align-center justify-space-between"
                            style="cursor: pointer; width: 100%"
                            @click="clickShowNotification"
                        >
                            <div class="d-flex">
                                <img
                                    :src="benachrichtigungenIcon"
                                    class="icon mr-2"
                                    alt="Benachrichtigungen"
                                >
                                <p class="font-weight-bold">
                                    Benachrichtigungen
                                </p>
                            </div>
                            <v-icon v-if="showNotificationArea">
                                mdi-chevron-up
                            </v-icon>
                            <v-icon v-else>
                                mdi-chevron-down
                            </v-icon>
                        </button>
                        <div v-if="showNotificationArea">
                            <div v-if="account.role === 'teacher'">
                                <div class="d-flex align-center mt-1">
                                    <p>Ruhezeit</p>

                                    <v-tooltip
                                        allow-overflow
                                        right
                                        max-width="400px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <img
                                                :src="infoIcon"
                                                class="icon pl-1"
                                                alt="Erklärung"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                        </template>
                                        <span>Sie können eine Zeitspanne angeben, in der Sie <strong>keine</strong> Benachrichtigungen von eklara erhalten möchten.</span>
                                    </v-tooltip>
                                </div>

                                <div class="d-flex flex-column mb-4">
                                    <div class="d-flex flex-row ">
                                        <div
                                            style="display: flex; justify-content: center; width: 50%"
                                        >
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <div
                                                        style="width: 50%; display: flex; align-items: center"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                        <v-menu
                                                            ref="startTimeMenu"
                                                            v-model="startTimeMenu"
                                                            :close-on-content-click="false"
                                                            :return-value.sync="breakStart"
                                                            transition="scale-transition"
                                                            offset-y
                                                            offset-overflow
                                                            max-width="290px"
                                                            min-width="290px"
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field
                                                                    v-model="breakStart"
                                                                    readonly
                                                                    hide-details
                                                                    dense
                                                                    outlined
                                                                    class="inputField"
                                                                    placeholder="Start"
                                                                    v-bind="attrs"
                                                                    :error="breakEnd === breakStart && breakEnd !== null"
                                                                    clearable
                                                                    v-on="on"
                                                                />
                                                            </template>
                                                            <v-time-picker
                                                                v-if="startTimeMenu"
                                                                v-model="breakStart"
                                                                format="24hr"
                                                                full-width
                                                                color="#282f44"
                                                                @click:hour="changingNotificationBreak = true"
                                                                @click:minute="saveNotificationBreak('start')"
                                                            />
                                                        </v-menu>
                                                    </div>
                                                </template>
                                                <span>Startzeit wählen</span>
                                            </v-tooltip>
                                        </div>

                                        <div style="display: flex; justiy-content: center; align-items: center; width: 10%">
                                            <span> bis </span>
                                        </div>

                                        <div
                                            style="display: flex; justify-content: center; width: 50%; align-items: center"
                                        >
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <div
                                                        style="width: 50%; display: flex"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                        <v-menu
                                                            ref="endTimeMenu"
                                                            v-model="endTimeMenu"
                                                            :close-on-content-click="false"
                                                            :return-value.sync="breakEnd"
                                                            transition="scale-transition"
                                                            offset-y
                                                            offset-overflow
                                                            max-width="290px"
                                                            min-width="290px"
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field
                                                                    v-model="breakEnd"
                                                                    readonly
                                                                    hide-details
                                                                    dense
                                                                    outlined
                                                                    class="inputField"
                                                                    placeholder="Ende"
                                                                    v-bind="attrs"
                                                                    :error="breakEnd === breakStart && breakEnd !== null"
                                                                    clearable
                                                                    v-on="on"
                                                                />
                                                            </template>
                                                            <v-time-picker
                                                                v-if="endTimeMenu"
                                                                v-model="breakEnd"
                                                                format="24hr"
                                                                full-width
                                                                color="#282f44"
                                                                @click:hour="changingNotificationBreak = true"
                                                                @click:minute="saveNotificationBreak('end')"
                                                            />
                                                        </v-menu>
                                                    </div>
                                                </template>
                                                <span>Endzeit wählen</span>
                                            </v-tooltip>
                                        </div>
                                    </div>
                                    <div
                                        v-if="breakInfo !== ''"
                                        class="d-flex flex-row justify-space-around"
                                    >
                                        <span style="font-size:12px; color: red;">{{ breakInfo }}</span>
                                    </div>
                                </div>
                            </div>
                            <v-badge
                                v-if="showEmailBadge"
                                content="3"
                                overlap
                                left
                                offset-y="20"
                                style="z-index: 9; width:100%"
                                color="#3ba934"
                                class="insetBadge"
                                @click.native="emailBadgeClicked"
                            />
                            <div class="d-flex align-center pl-2">
                                <p>E-Mail (Push - Benachrichtigungen)</p>

                                <v-tooltip
                                    allow-overflow
                                    right
                                    max-width="400px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <img
                                            :src="infoIcon"
                                            class="icon pl-1"
                                            alt="Erklärung"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                    </template>
                                    <span>Sie können Ihre E-Mail-Adresse angeben, um Benachrichtigungen von eklara per E-Mail zu erhalten. 
                                        Diese muss bei Änderung mit ihrem eklara Passwort bestätigt werden.</span>
                                </v-tooltip>
                            </div>
                            <div
                                class="d-flex justify-space-between align-center"
                                style="max-width: 400px;"
                            >
                                <v-text-field
                                    v-model="email"
                                    type="text"
                                    :placeholder="`E-Mail eingeben ${accountRole === 'parent' ? '(Eltern 1)' : ''}`"
                                    outlined
                                    dense
                                    hide-details
                                    clearable
                                    class="inputField mb-2"
                                />
                                <v-btn
                                    :disabled="!isEmailChanged"
                                    background-color="green"
                                    class="mb-2 ml-2"
                                    style="background-color: var(--v-chatColor-base); color: white;height: 30px; max-width:28px; min-width: 28px;"
                                    @click="saveEmail"
                                >
                                    <img
                                        class="iconToWhite icon20"
                                        alt="bestätigen"
                                        :src="checkIcon"
                                    >
                                </v-btn>
                            </div>
                            <div
                                v-if="accountRole === 'parent'"
                                class="d-flex justify-space-between align-center"
                                style="max-width: 400px;"
                            >
                                <v-text-field
                                    v-model="alternateEmail"
                                    type="text"
                                    placeholder="E-Mail eingeben (Eltern 2 - optional)"
                                    outlined
                                    dense
                                    hide-details
                                    class="inputField mb-2"
                                    clearable
                                />
                                <v-btn
                                    :disabled="!isAlternateEmailChanged"
                                    background-color="green"
                                    class="mb-2 ml-2"
                                    style="background-color: var(--v-chatColor-base); color: white;height: 30px; max-width:28px; min-width: 28px;"
                                    @click="saveAlternateEmail"
                                >
                                    <img
                                        class="iconToWhite icon20"
                                        alt="bestätigen"
                                        :src="checkIcon"
                                    >
                                </v-btn>
                            </div>
                            <!-- <v-text-field
                                    v-if="!isPasswordVerified"
                                    v-model="verifyPassword"
                                    :type="showEmailPw ? 'text' : 'password'"
                                    placeholder="Mit eklara Passwort bestätigen"
                                    outlined
                                    dense
                                    hide-details
                                    class="inputField mb-2 mr-2"
                                >
                                    <template v-slot:append>
                                        <img
                                            tabindex="0"
                                            role="button"
                                            :alt="showEmailPw ? 'Passwort ausblenden' : 'Passwort anzeigen'"
                                            :src="showEmailPw ? eyeIcon : eyeOffIcon"
                                            style="display: block; height: 25px; margin-top: -5px; color: #747474; margin-right: 5px"
                                            @click="showEmailPw = !showEmailPw"
                                        >
                                    </template>
                                </v-text-field> -->



                            
                            <div class="d-flex flex-column mb-4">
                                <div
                                    v-if="accountRole === 'maintainer' || accountRole === 'teacher'"
                                    class="switchRow mb-2"
                                >
                                    <div class="d-flex flex-row justify-space-around">
                                        <img
                                            :src="abwesendIcon"
                                            style="display: block; height: 19px; margin-top: 3px; color: #747474; margin-right: 5px"
                                        >
                                        <p style="font-size:larger">
                                            Abwesenheit(LuL)
                                        </p>
                                    </div>
                                    <v-switch
                                        v-model="emailNotificationSubstitution"
                                        inset
                                        hide-details
                                        :disabled="email === ''"
                                        color="success"
                                        class="mt-0"
                                        @change="saveNotifications"
                                    />
                                </div>
                                <div
                                    v-if="accountRole !== 'maintainer'"
                                    class="switchRow mb-2"
                                >
                                    <div class="d-flex flex-row justify-space-around">
                                        <img
                                            :src="brettIcon"
                                            style="display: block; height: 23px; color: #747474; margin-right: 5px"
                                        >
                                        <p style="font-size:larger">
                                            Schwarzes Brett
                                        </p>
                                    </div>
                                    <v-switch
                                        v-model="emailNotificationBlackboard"
                                        :disabled="email === ''"
                                        inset
                                        hide-details
                                        color="success"
                                        class="mt-0"
                                        @change="saveNotifications"
                                    />
                                </div>
                                <div
                                    class="switchRow mb-2"
                                >
                                    <div class="d-flex flex-row justify-space-around">
                                        <img
                                            :src="mailIcon"
                                            class="iconToPurple"
                                            style="display: block; height: 23px; margin-top: 3px; color: #747474; margin-right: 5px"
                                        >
                                        <p style="font-size:larger">
                                            Elternbriefe
                                        </p>
                                    </div>
                                    <v-switch
                                        v-model="emailNotificationParentalLetter"
                                        inset
                                        :disabled="email === ''"
                                        hide-details
                                        color="success"
                                        class="mt-0"
                                        @change="saveNotifications"
                                    />
                                </div>
                                <div
                                    v-if="account.role === 'teacher'"
                                    class="switchRow mb-2"
                                >
                                    <div class="d-flex flex-row justify-space-around">
                                        <img
                                            :src="schuelerIcon"
                                            class="iconToPurple"
                                            style="display: block; height: 23px; margin-top: 3px; color: #747474; margin-right: 5px"
                                        >
                                        <p style="font-size:larger">
                                            Abwesenheit(SuS)
                                        </p>
                                    </div>
                                    <v-switch
                                        v-model="emailNotificationSicknote"
                                        inset
                                        hide-details
                                        :disabled="email === ''"
                                        color="success"
                                        class="mt-0"
                                        @change="saveNotifications"
                                    />
                                </div>
                                <div
                                    class="switchRow mb-2"
                                >
                                    <div class="d-flex flex-row justify-space-around">
                                        <img
                                            :src="kalenderIcon"
                                            style="display: block; height: 23px; margin-top: 3px; color: #747474; margin-right: 5px"
                                        >
                                        <p style="font-size:larger">
                                            Kalender
                                        </p>
                                    </div>
                                    <v-switch
                                        v-model="emailNotificationCalendar"
                                        inset
                                        hide-details
                                        :disabled="email === ''"
                                        color="success"
                                        class="mt-0"
                                        @change="saveNotifications"
                                    />
                                </div>
                                <div
                                    class="switchRow mb-2"
                                >
                                    <div class="d-flex flex-row justify-space-around">
                                        <img
                                            :src="chatIcon"
                                            style="display: block; height: 23px; margin-top: 3px; color: #747474; margin-right: 5px"
                                        >
                                        <p style="font-size:larger">
                                            Chat
                                        </p>
                                    </div>
                                    <v-switch
                                        v-model="emailNotificationChat"
                                        inset
                                        hide-details
                                        :disabled="email === ''"
                                        color="success"
                                        class="mt-0"
                                        @change="saveNotifications"
                                    />
                                </div>
                                <div
                                    class="switchRow mb-2"
                                >
                                    <div class="d-flex flex-row justify-space-around">
                                        <img
                                            :src="appIcon"
                                            style="display: block; height: 23px; margin-top: 3px;  margin-right: 5px"
                                            class="iconToDunkelgrau"
                                        >
                                        <p style="font-size:larger">
                                            Pinnwand
                                        </p>
                                    </div>
                                    <v-switch
                                        v-model="emailNotificationPinboard"
                                        inset
                                        hide-details
                                        :disabled="email === ''"
                                        color="success"
                                        class="mt-0"
                                        @change="saveNotifications"
                                    />
                                </div>
                            </div>
                            <div class="switchRow mb-2">
                                <p>
                                    <span style="font-weight: bold"> Push-Benachrichtigungen ohne Email erwünscht? </span>
                                    Hole Dir die eklara + App im <a href="itms-apps://apps.apple.com/de/app/eklara/id1672585024">Apple Store</a>
                                    oder <a href="https://play.google.com/store/apps/details?id=com.eklara.buddy_app">Google Play Store</a>.
                                </p>
                            </div>
                            <div class="d-flex flex-row mb-2">
                                <p>
                                    <span style="font-weight: bold"> Benachrichtigung nicht erhalten?</span>
                                </p>
                                <v-tooltip
                                    allow-overflow
                                    top
                                    max-width="400px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <img
                                            :src="infoIcon"
                                            class="icon pl-1"
                                            alt="Erklärung"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                    </template>
                                    <span>Um Benachrichtigungs-Spam zu vermeiden versenden wir im Chat und der Pinnwand erst eine weitere Benachrichtigung, sobald Sie mit dem Chat interagieren oder spätestens nach einer Stunde.</span>
                                </v-tooltip>
                            </div>
                            <!--div class="d-flex align-center">
                                <p>SMS</p>
                                <v-tooltip
                                    allow-overflow
                                    right
                                    max-width="400px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <img
                                            :src="infoIcon"
                                            class="icon pl-1"
                                            alt="Erklärung"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                    </template>
                                    <span>Sie können Ihre Telefonnummer angeben, um Benachrichtigungen von eKlara per SMS zu erhalten.</span>
                                </v-tooltip>
                            </div>
                            <v-form
                                ref="form"
                                v-model="validNumber"
                                lazy-validation
                            >
                                <v-text-field
                                    v-model="telephone"
                                    outlined
                                    dense
                                    :type="showTelephone ? 'text':'password'"
                                    hide-details
                                    prefix="+49 "
                                    :rules="telephoneRules"
                                    class="inputField mb-2"
                                    @change="saveProfile"
                                    @blur="saveProfile"
                                >
                                    <template v-slot:append>
                                        <img
                                            tabindex="0"
                                            role="button"
                                            :alt="showTelephone ? 'Passwort ausblenden' : 'Passwort anzeigen'"
                                            :src="showTelephone ? eyeIcon : eyeOffIcon"
                                            style="display: block; height: 25px; margin-top: -5px; color: #747474; margin-right: 5px"
                                            @click="showTelephone = !showTelephone"
                                        >
                                    </template>
                                </v-text-field>
                            </v-form>
                            <p
                                v-if="!validNumber"
                                class="mt-0"
                                style="color: red; font-size: 12px;"
                            >
                                Gülige Telefonnummer eingeben!
                            </p>
                            <div class="d-flex flex-column mb-4">
                                <div
                                    v-if="accountRole === 'maintainer' || accountRole === 'teacher'"
                                    class="switchRow mb-2"
                                >
                                    <div class="d-flex flex-row justify-space-around">
                                        <v-icon class="mr-2">
                                            mdi-email
                                        </v-icon>
                                        <p style="font-size:larger">
                                            Abwesenheit(LuL)
                                        </p>
                                    </div>
                                    <v-switch
                                        v-model="smsNotificationSubstitution"
                                        inset
                                        hide-details
                                        :disabled="!validNumber || telephone === ''"
                                        color="success"
                                        class="mt-0"
                                        @change="saveNotifications"
                                    />
                                </div>
                                <div
                                    v-if="accountRole !== 'maintainer'"
                                    class="switchRow mb-2"
                                >
                                    <div class="d-flex flex-row justify-space-around">
                                        <v-icon class="mr-2">
                                            mdi-email
                                        </v-icon>
                                        <p style="font-size:larger">
                                            Schwarzes Brett
                                        </p>
                                    </div>
                                    <v-switch
                                        v-model="smsNotificationBlackboard"
                                        :disabled="!validNumber || telephone === ''"
                                        inset
                                        hide-details
                                        color="success"
                                        class="mt-0"
                                        @change="saveNotifications"
                                    />
                                </div>
                                <div
                                    v-if="accountRole !== 'maintainer'"
                                    class="switchRow mb-2"
                                >
                                    <div class="d-flex flex-row justify-space-around">
                                        <v-icon class="mr-2">
                                            mdi-chat-outline
                                        </v-icon>
                                        <p style="font-size:larger">
                                            Chat
                                        </p>
                                    </div>
                                    <v-switch
                                        v-model="smsNotificationChat"
                                        :disabled="!validNumber || telephone === ''"
                                        inset
                                        hide-details
                                        color="success"
                                        class="mt-0"
                                        @change="saveNotifications"
                                    />
                                </div>
                                <div
                                    class="switchRow mb-2"
                                >
                                    <div class="d-flex flex-row justify-space-around">
                                        <v-icon
                                            class="mr-2"
                                            color="flila"
                                        >
                                            mdi-email
                                        </v-icon>
                                        <p style="font-size:larger">
                                            Elternbriefe
                                        </p>
                                    </div>
                                    <v-switch
                                        v-model="smsNotificationParentalLetter"
                                        :disabled="!validNumber || telephone === ''"
                                        inset
                                        hide-details
                                        color="success"
                                        class="mt-0"
                                        @change="saveNotifications"
                                    />
                                </div>
                                <div
                                    v-if="account.role === 'teacher'"
                                    class="switchRow mb-2"
                                >
                                    <div class="d-flex flex-row justify-space-around">
                                        <v-icon
                                            class="mr-2"
                                            color="flila"
                                        >
                                            mdi-emoticon-sick-outline
                                        </v-icon>
                                        <p style="font-size:larger">
                                            Entschuldigungen
                                        </p>
                                    </div>
                                    <v-switch
                                        v-model="smsNotificationSicknote"
                                        :disabled="!validNumber || telephone === ''"
                                        inset
                                        hide-details
                                        color="success"
                                        class="mt-0"
                                        @change="saveNotifications"
                                    />
                                </div>
                                <div
                                    class="switchRow mb-2"
                                >
                                    <div class="d-flex flex-row justify-space-around">
                                        <v-icon
                                            class="mr-2"
                                        >
                                            mdi-calendar
                                        </v-icon>
                                        <p style="font-size:larger">
                                            Kalender
                                        </p>
                                    </div>
                                    <v-switch
                                        v-model="smsNotificationCalendar"
                                        :disabled="!validNumber || telephone === ''"
                                        inset
                                        hide-details
                                        color="success"
                                        class="mt-0"
                                        @change="saveNotifications"
                                    />
                                </div>
                            </div-->
                        </div>
                    </div>
                    <!--#endregion -->

                    <!-- <div class="d-flex flex-column">
                        <div v-if="account.role !== 'maintainer'">
                            <v-divider class="mb-2" />
                            <button
                                class="d-flex align-center justify-space-between"
                                style="cursor: pointer; width: 100%"
                                @click="showPermissionsArea = !showPermissionsArea"
                            >
                                <div class="d-flex align-center">
                                    <img
                                        :src="cameraIcon"
                                        class="icon mr-2"
                                        alt="Berechtigungen"
                                    >
                                    <p class="font-weight-bold">
                                        Berechtigungen
                                    </p>
                                </div>
                                <v-icon v-if="showPermissionsArea">
                                    mdi-chevron-up
                                </v-icon>
                                <v-icon v-else>
                                    mdi-chevron-down
                                </v-icon>
                            </button>
                            <div
                                v-if="showPermissionsArea"
                                class="d-flex flex-column justify-space-between mt-1"
                            />
                        </div>
                    </div> -->
                    <!-- #region dokumente -->
                    <div
                        v-if="account.role === 'maintainer' || account.role === 'teacher'"
                        class="d-flex flex-column mb-1"
                    >
                        <v-divider class="my-2" />
                        <v-badge
                            v-if="accountRole ==='maintainer' && !advSigned && !showDocuments"
                            content="1"
                            overlap
                            left
                            color="red"
                            style="z-index: 9; width:100%"
                        />
                        <button
                            class="d-flex justify-space-between"
                            style="cursor: pointer; width: 100%"
                            @click="showDocuments = !showDocuments"
                        >
                            <div class="d-flex">
                                <img
                                    :src="documentIcon"
                                    class="icon mr-2"
                                    alt="Dokumente"
                                >
                                <p class="font-weight-bold ">
                                    Dokumente
                                </p>
                            </div>
                        
                            <v-icon v-if="showDocuments">
                                mdi-chevron-up
                            </v-icon>
                            <v-icon v-else>
                                mdi-chevron-down
                            </v-icon>
                        </button>
                        
                        <div v-if="showDocuments">
                            <div v-if="accountRole === 'maintainer'">
                                <div
                                    :class="!advSigned?'ml-2':''"
                                >
                                    <v-badge
                                        v-if="!advSigned"
                                        content="1"
                                        color="red"
                                        left
                                        overlap
                                        style="z-index: 9; width:100%"
                                    />
                                    <div class="d-flex flex-row justify-space-between align-center">
                                        <b
                                            class="ml-2 ma-0"
                                            style="text-transform: none !important"
                                        >
                                            ADV-Vertrag
                                        </b>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <img
                                                    :src="advSigned ? checkIcon : schließenIcon"
                                                    class="icon20 ml-1"
                                                    :class="advSigned ? 'iconToGreen' : 'iconToRed'"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                            </template>
                                            <span>{{ advSigned ? 'Der Vertrag für die Auftragsdatenverarbeitung wurde bereits unterzeichnet.' : 'Der Vertrag für die Auftragsdatenverarbeitung wurde noch nicht unterzeichnet.' }}</span>
                                        </v-tooltip>
                                        <v-spacer />
                                        <v-btn
                                            v-if="accountRole === 'maintainer'"
                                            v-ripple
                                            class="backButton pointer buttonLength"
                                            style="justify-content: left;"
                                            elevation="0"
                                            @click="handleBackClick(false); setShowDialogGDPR(true)"
                                        >
                                            <v-icon
                                                color="grey"
                                                size="25"
                                            >
                                                mdi-file-document-edit-outline
                                            </v-icon>
                                            <p
                                                class="ml-2 ma-0"
                                                style="text-transform: none !important"
                                            >
                                                {{ advSigned ? 'bearbeiten' :'unterzeichnen' }}
                                            </p>
                                        </v-btn>
                                    </div>
                                </div>
                                <div
                                    :class="!advSigned?'ml-2':''"
                                >
                                    <div
                                        class="d-flex flex-row justify-space-between mt-2 align-center"
                                    >
                                        <b
                                            class="ml-1 ma-0"
                                            style="text-transform: none !important"
                                        >
                                            Einwilligungen
                                        </b>
                                        <v-tooltip
                                            allow-overflow
                                            right
                                            max-width="400px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <img
                                                    :src="infoIcon"
                                                    class="icon pl-1"
                                                    alt="Erklärung"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                            </template>
                                            <span>Hier können Sie die Übersicht zur Einverständniserklärung für den Datenschutz und die Nutzungsbedingungen pro Nutzer herunterladen</span>
                                        </v-tooltip>
                                        <v-spacer />
                                        <v-btn
                                            v-ripple
                                            class="backButton pointer mb-2 buttonLength"
                                            elevation="0"
                                            @click="() => { exportUsageAgreementStats() }"
                                        >
                                            <v-icon
                                                size="25"
                                            >
                                                mdi-file-export-outline
                                            </v-icon>
                                            <p
                                                class="ml-1 ma-0"
                                                style="text-transform: none !important"
                                            >
                                                exportieren
                                            </p>
                                        </v-btn>
                                    </div>
                                </div>
                                <div
                                    :class="!advSigned?'ml-2':''"
                                >
                                    <div
                                        class="d-flex flex-row justify-space-between mt-2 align-center"
                                    >
                                        <b
                                            class="ml-1 ma-0"
                                            style="text-transform: none !important"
                                        >
                                            Einwilligungen Externer
                                        </b>
                                        <v-tooltip
                                            allow-overflow
                                            right
                                            max-width="400px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <img
                                                    :src="infoIcon"
                                                    class="icon pl-1"
                                                    alt="Erklärung"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                            </template>
                                            <span>Hier können Sie die Übersicht zur Einverständniserklärung für den Datenschutz und die Nutzungsbedingungen für externe Nutzer herunterladen</span>
                                        </v-tooltip>
                                        <v-spacer />
                                        <v-btn
                                            v-ripple
                                            class="backButton pointer mb-2 buttonLength"
                                            elevation="0"
                                            @click="() => { exportUsageAgreementsExterne() }"
                                        >
                                            <v-icon
                                                size="25"
                                            >
                                                mdi-file-export-outline
                                            </v-icon>
                                            <p
                                                class="ml-1 ma-0"
                                                style="text-transform: none !important"
                                            >
                                                exportieren
                                            </p>
                                        </v-btn>
                                    </div>
                                </div>
                                <div
                                    :class="!advSigned? 'ml-4':'ml-2'"
                                >
                                    <div
                                        class="d-flex flex-row justify-space-between mt-2 align-center"
                                    >
                                        <b
                                            style="text-transform: none !important; "
                                        >
                                            Datenschutzpaket
                                        </b>
                                        <v-tooltip
                                            allow-overflow
                                            right
                                            max-width="400px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <img
                                                    :src="infoIcon"
                                                    class="icon pl-1"
                                                    alt="Erklärung"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                            </template>
                                            <span>Hier können Sie die Vorlagen für Datenschutzinformation, Nutzungserklärung sowie Einverständniserklärung für Schüler/-innen, Eltern und Lehrende herunterladen.</span>
                                        </v-tooltip>
                                        <v-spacer />
                                        <v-btn
                                            v-ripple
                                            class="backButton pointer mb-2 buttonLength"
                                            elevation="0"
                                            @click="() => { downloadDatenschutzFiles() }"
                                        >
                                            <v-icon
                                                size="20"
                                            >
                                                mdi-file-multiple
                                            </v-icon>
                                            <p
                                                class="ml-1 ma-0"
                                                style="text-transform: none !important;"
                                            >
                                                exportieren
                                            </p>
                                        </v-btn>
                                    </div>
                                </div>
                            </div>
                            <div v-if="accountRole === 'teacher' && groupsByLeader(userrole._id).length > 0">
                                <div
                                    class="d-flex flex-row justify-space-between mt-2 align-center"
                                >
                                    <b
                                        style="text-transform: none !important"
                                    >
                                        Einwilligungen
                                    </b>
                                    <v-tooltip
                                        allow-overflow
                                        right
                                        max-width="400px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <img
                                                :src="infoIcon"
                                                class="icon pl-1"
                                                alt="Erklärung"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                        </template>
                                        <span>Hier können sie die Übersicht zur Einverständniserklärung für den Datenschutz und die Nutzungsbedingungen für Eltern und Schüler ihrer Klasse(n) herunterladen.</span>
                                    </v-tooltip>
                                    <v-spacer />
                                    <v-btn
                                        v-ripple
                                        class="backButton pointer mb-2 buttonLength"
                                        elevation="0"
                                        @click="() => { showSelectGroupDialog = true; selectedTeacherGroup = groupsByLeader(userrole._id)[0]}"
                                    >
                                        <v-icon
                                            size="25"
                                        >
                                            mdi-file-export-outline
                                        </v-icon>
                                        <p
                                            class="ml-2 ma-0"
                                            style="text-transform: none !important"
                                        >
                                            exportieren
                                        </p>
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- #endregion -->
                    <!-- region storage management -->
                    <!-- <div
                        v-if="account.role === 'maintainer'"
                        class="d-flex flex-column"
                    >
                        <v-divider class="my-2" />
                        <button
                            class="d-flex justify-space-between"
                            style="cursor: pointer; width: 100%"
                            @click="showStorageManagement = !showStorageManagement"
                        >
                            <div class="d-flex justify-space-around">
                                <div class="d-flex">
                                    <img
                                        :src="saveIcon"
                                        class="icon mr-2"
                                        alt="Zensur"
                                    >
                                    <p class="font-weight-bold ">
                                        Speicherplatz verwalten
                                    </p>
                                    <v-tooltip
                                        allow-overflow
                                        right
                                        max-width="400px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <img
                                                :src="infoIcon"
                                                class="icon pl-1"
                                                alt="Erklärung"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                        </template>
                                        <span>Hier können sie den Speicherplatz der gesamten Schule verwalten.
                                            Sollte der Speicherplatz nicht ausreichen, melden Sie sich bitte beim eKlara Team für ein kostenpflichtiges Upgrade.</span>
                                    </v-tooltip>
                                </div>
                            </div>
                            <v-icon v-if="showStorageManagement">
                                mdi-chevron-up
                            </v-icon>
                            <v-icon v-else>
                                mdi-chevron-down
                            </v-icon>
                        </button>
                        <div
                            v-if="showStorageManagement"
                            class="d-flex flex-column mx-0"
                        >
                            <div class="storageListItem">
                                <p>Insgesamter Speicherplatz</p> <p>{{ totalStorage }}</p>
                            </div>
                            <div class="storageListItem">
                                <p>Belegter Speicherplatz</p><p>{{ usedStorage }}</p>
                            </div>
                            <div class="storageListItem">
                                <p>Anzahl Lehrer</p><p>{{ teacherCount }}</p>
                            </div>
                            <div class="storageListItem">
                                <p>Anzahl Schüler</p><p>{{ pupilCount }}</p>
                            </div>
                            <div class="storageListItem">
                                <p>Empfohlener Speicherplatz</p><p> 100 mb</p>
                            </div>
                            <div class="storageListItem">
                                <p>Speicherplatz pro Lehrer</p>
                                <div class="d-flex flex-row justify-space-around align-baseline">
                                    <v-text-field
                                        v-model="teacherStorage"
                                        type="number"
                                        style="max-width: 40px;"
                                        hide-details
                                        hide-spin-buttons
                                        dense
                                    >
                                        {{ teacherStorage }}
                                    </v-text-field>
                                    mb
                                </div>
                            </div>
                            <div class="storageListItem">
                                <p>Speicherplatz pro Schüler</p>
                                <div class="d-flex flex-row justify-space-around align-baseline">
                                    <v-text-field
                                        v-model="pupilStorage"
                                        type="number"
                                        style="max-width: 40px;"
                                        hide-details
                                        hide-spin-buttons
                                        dense
                                    >
                                        {{ pupilStorage }}
                                    </v-text-field>
                                    mb
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- endregion -->
                </div>

                <!-- Password and 2FA Two factor -->
                <div
                    class="pa-2"
                >
                    <!--#region Password -->
                    <v-divider class="mb-2" />
                    <button
                        class="d-flex justify-space-between"
                        style="cursor: pointer; width: 100%"
                        @click="showChangePassword = !showChangePassword"
                    >
                        <div class="d-flex">
                            <img
                                :src="thumbIcon"
                                class="icon mr-2"
                                alt="Passwort ändern"
                            >
                            <p class="font-weight-bold">
                                Sicherheit
                            </p>
                        </div>
                        <v-icon v-if="showChangePassword">
                            mdi-chevron-up
                        </v-icon>
                        <v-icon v-else>
                            mdi-chevron-down
                        </v-icon>
                    </button>
                    <div
                        v-if="showChangePassword"
                    >
                        <div
                            v-if="accountRole !== 'pupil'"
                        >
                            <div
                                class="d-flex justify-space-between pa-0 my-1 mx-0"
                            >
                                <b aria-hidden="true">
                                    Zwei-Faktor-Authentifizierung
                                </b>

                                <v-tooltip
                                    top
                                    max-width="400px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <img
                                            :src="infoIcon"
                                            class="icon"
                                            alt="Erklärung"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                    </template>
                                    <span>Wenn Sie die Zwei-Faktor-Authentifizierung aktivieren, müssen Sie bei jeder Anmeldung zusätzlich zu Ihrem Nutzernamen und Passwort noch einen Code, den Sie per E-Mail erhalten, eingeben.</span>
                                </v-tooltip>
                                <v-spacer />
                                <v-switch
                                    v-model="twoFactor"
                                    inset
                                    aria-label="Zwei-Faktor-Authentifizierung"
                                    hide-details
                                    :disabled="!isEmailExisting"
                                    class="ma-0 pa-0 pl-1 mb-1"
                                    color="success"
                                />
                            </div>
                            <p
                                v-if="!isEmailExisting && accountRole !== 'pupil'"
                                class="mb-2"
                                style="font-size:smaller"
                            >
                                Tragen Sie hier Ihre E-Mail ein, um die Aktivierung der Zwei-Faktor-Authentifizierung zu starten.
                            </p>
                            <div
                                v-if="!isEmailExisting && accountRole !== 'pupil'"
                                class="d-flex justify-space-between align-center"
                                style="max-width: 400px;"
                            >
                                <v-text-field
                                    v-model="email"
                                    type="text"
                                    placeholder="E-Mail eingeben"
                                    outlined
                                    dense
                                    hide-details
                                    clearable
                                    class="inputField mb-2"
                                />
                                <v-btn
                                    :disabled="!isEmailChanged"
                                    background-color="green"
                                    class="mb-2 ml-2"
                                    style="background-color: var(--v-chatColor-base); color: white;height: 30px; max-width:28px; min-width: 28px;"
                                    @click="saveEmail"
                                >
                                    <img
                                        class="iconToWhite icon20"
                                        alt="bestätigen"
                                        :src="checkIcon"
                                    >
                                </v-btn>
                            </div>
                            <div
                                v-if="accountRole !== 'pupil' && twoFactorMenu"
                                class="d-flex pa-0 ma-0"
                                style="flex-direction: column;"
                            >
                                <v-text-field
                                    v-if="!twoFactorEmailIsVerified && twoFactorMenu"
                                    v-model="email"
                                    :disabled="true"
                                    outlined
                                    dense
                                    hide-details
                                    class="inputField mb-3"
                                    style="width: 100%; align-self: start;"
                                />
                                <v-text-field
                                    v-if="twoFactorEmailIsVerified && twoFactorMenu"
                                    v-model="twoFactorPassword"
                                    label="Anmelde - Passwort wiederholen"
                                    type="password"
                                    outlined
                                    dense
                                    hide-details
                                    class="inputField mb-3"
                                    style="width: 100%; align-self: start;"
                                />
                                <v-text-field
                                    v-if="twoFactorEmailIsVerified && twoFactorMenu"
                                    v-model="twoFactorOTP"
                                    label="Code aus Email eingeben"
                                    outlined
                                    dense
                                    hide-details
                                    class="inputField mb-3"
                                    style="width: 100%; align-self: start;"
                                />

                                <v-btn
                                    v-if="!twoFactorEmailIsVerified && twoFactorMenu"
                                    class="changePwBtn text-none "
                                    style="width: 50%; align-self: end;"
                                    elevation="0"
                                    @click="activate2FA"
                                >
                                    Nächster Schritt
                                </v-btn>
                                <v-btn
                                    v-if="twoFactorEmailIsVerified && twoFactorMenu && twoFactor"
                                    :disabled="!(twoFactorPassword.length > 0 && twoFactorOTP.length > 0)"
                                    class="changePwBtn text-none"
                                    style="width: 50%; align-self: end;"
                                    elevation="0"
                                    @click="activate2FA"
                                >
                                    2FA aktivieren
                                </v-btn>
                                <v-btn
                                    v-if="twoFactorEmailIsVerified && twoFactorMenu && !twoFactor"
                                    :disabled="!(twoFactorPassword.length > 0 && twoFactorOTP.length > 0)"
                                    class="changePwBtn text-none"
                                    style="width: 50%; align-self: end;"
                                    elevation="0"
                                    @click="deactivate2FA"
                                >
                                    2FA deaktivieren!
                                </v-btn>
                            </div>
                            <div
                                v-if="otherConnectedDevices > 0"
                                class="d-flex justify-space-between pa-0 my-1 mx-0"
                            >
                                <b aria-hidden="true">
                                    Andere Geräte: {{ otherConnectedDevices }}
                                </b>
                                <v-tooltip
                                    top
                                    max-width="400px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <img
                                            :src="infoIcon"
                                            class="icon"
                                            alt="Erklärung"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                    </template>
                                    <span>Sie Können mit Klick auf den Button ihre anderen Geräte ausloggen.</span>
                                </v-tooltip>
                                <v-spacer />
                                <v-btn
                                    class="ma-0 pa-0 pl-1 mb-1"
                                    @click="showForceLogoutDialog = true;"
                                >
                                    Ausloggen
                                </v-btn>
                            </div>
                        </div>
                        <div
                            v-if="accountRole !== 'pupil' || account.canChangePassword"
                        >
                            <p
                                class="font-weight-bold mt-1"
                            >
                                Passwort ändern
                            </p>
                            <v-text-field
                                v-model="oldPassword"
                                :type="showPasswordOld ? 'text' : 'password'"
                                outlined
                                dense
                                hide-details
                                placeholder="Altes Passwort eingeben"
                                class="inputField mb-2"
                            >
                                <template v-slot:append>
                                    <img
                                        tabindex="0"
                                        role="button"
                                        :alt="showPasswordOld ? 'Passwort ausblenden' : 'Passwort anzeigen'"
                                        :src="showPasswordOld ? eyeIcon : eyeOffIcon"
                                        style="display: block; height: 25px; margin-top: -5px; color: #747474; margin-right: 5px"
                                        @click="showPasswordOld = !showPasswordOld"
                                    >
                                </template>
                            </v-text-field>

                            <v-text-field
                                v-model="newPassword"
                                :type="showPasswordNew ? 'text' : 'password'"
                                :disabled="oldPassword === ''"
                                outlined
                                dense
                                hide-details
                                placeholder="Neues Passwort eingeben"
                                class="inputField mb-2"
                            >
                                <template v-slot:append>
                                    <img
                                        tabindex="0"
                                        role="button"
                                        :alt="showPasswordNew ? 'Passwort ausblenden' : 'Passwort anzeigen'"
                                        :src="showPasswordNew ? eyeIcon : eyeOffIcon"
                                        style="display: block; height: 25px; margin-top: -5px; color: #747474; margin-right: 5px"
                                        @click="showPasswordNew = !showPasswordNew"
                                    >
                                </template>
                            </v-text-field>

                            <v-text-field
                                v-model="newPasswordRepeat"
                                :type="showPassword ? 'text' : 'password'"
                                :disabled="newPassword === ''"
                                outlined
                                dense
                                hide-details
                                placeholder="Neues Passwort wiederholen"
                                class="inputField mb-2"
                                @blur="saveProfile"
                            >
                                <template v-slot:append>
                                    <img
                                        tabindex="0"
                                        role="button"
                                        :alt="showPassword ? 'Passwort ausblenden' : 'Passwort anzeigen'"
                                        :src="showPassword ? eyeIcon : eyeOffIcon"
                                        style="display: block; height: 25px; margin-top: -5px; color: #747474; margin-right: 5px"
                                        @click="showPassword = !showPassword"
                                    >
                                </template>
                            </v-text-field>
                            <p
                                style="font-size:smaller"
                            >
                                Das Passwort muss mind. 10 Zeichen lang sein und aus Buchstaben, Zahlen und Sonderzeichen bestehen.
                            </p>
                            <div class="d-flex justify-end mb-2">
                                <v-btn
                                    color="success"
                                    :disabled="!(newPassword !== '' && checkPw)"
                                >
                                    Bestätigen
                                </v-btn>
                            </div>
                        </div>
                        <div
                            v-if="((account.role === 'teacher' || account.role === 'parent') && (diaryPinActivated || messagesPinActivated)) || account.role === 'maintainer'"
                            class="d-flex flex-column mb-2 mt-1"
                        >
                            <div
                                v-if="account.role === 'maintainer'"
                                class="d-flex flex-row justify-space-between mt-2"
                            >
                                <div class="d-flex flex-row justify-space-around">
                                    <p style="font-weight: bold">
                                        Persönliche PIN 
                                    </p>
                                    <v-tooltip
                                        allow-overflow
                                        top
                                        max-width="400px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <img
                                                :src="infoIcon"
                                                class="icon pl-1"
                                                alt="Erklärung"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                        </template>
                                        <span>Aktivieren sie eine zusätzliche persönliche Pin für besonders sensible Inhalte wie Elternbrief, Nutzerverwaltung, Chat und Klassenbuch.</span>
                                    </v-tooltip>
                                </div>
                                <v-switch
                                    v-model="pinActivatedMaintainer"
                                    inset
                                    hide-details
                                    color="success"
                                    style="margin-top: 0; padding-top: 0; margin-bottom:1.5%"
                                    @change="saveMaintainerPin"
                                />
                            </div>
                            <button
                                v-if="(accountRole === 'maintainer' && pinActivatedMaintainer) || (accountRole !== 'maintainer')"
                                class="d-flex justify-space-between"
                                style="cursor: pointer; width: 100%"
                                @click="showChangePin = !showChangePin"
                            >
                                <div class="d-flex flex-row justify-space-around">
                                    <p
                                        class="font-weight-bold"
                                    >
                                        Persönliche PIN {{ accountHasPin ? 'ändern' : 'erstellen' }}
                                    </p>
                                    <v-tooltip
                                        allow-overflow
                                        top
                                        max-width="400px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <img
                                                :src="infoIcon"
                                                class="icon pl-1"
                                                alt="Erklärung"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                        </template>
                                        <span>Zusätzliche persönliche PIN zum Absichern von besonders sensiblen Inhalten wie z.B Klassenbuch oder Elternbrief.</span>
                                    </v-tooltip>
                                </div>
                                <v-icon v-if="showChangePin">
                                    mdi-chevron-up
                                </v-icon>
                                <v-icon v-else>
                                    mdi-chevron-down
                                </v-icon>
                            </button>
                            <div v-if="showChangePin && (accountRole === 'maintainer' && pinActivatedMaintainer) || (accountRole !== 'maintainer')">
                                <div v-if="!initialPinValid && accountHasPin">
                                    <div class="d-flex flex-column">
                                        <p>Aktuelle PIN eingeben</p><v-otp-input
                                            v-model="initialPin"
                                            type="password"
                                            length="4"
                                            style="max-width: 250px; max-height: 60px;"
                                            class="custom-otp-input"
                                            @finish="checkInitialPin"
                                        />
                                    </div>
                                </div>
                                <div v-if="!accountHasPin || initialPinValid">
                                    <div class="d-flex flex-column">
                                        <p>Neue PIN eingeben</p><v-otp-input
                                            v-model="newPin"
                                            type="password"
                                            length="4"
                                            style="max-width: 250px; max-height: 60px;"
                                            class="custom-otp-input"
                                        />
                                    </div>
                                    <div class="d-flex flex-column">
                                        <p>Neue PIN wiederholen</p><v-otp-input
                                            v-model="repeatNewPin"
                                            type="password"
                                            length="4"
                                            style="max-width: 250px;"
                                            class="custom-otp-input"
                                        />
                                    </div>
                                    <div
                                        class="d-flex flex-row justify-end"
                                        style="margin-top: -25px;"
                                    >
                                        <v-btn
                                            color="success"
                                            :disabled="!(newPin === repeatNewPin && newPin !== null)"
                                            @click="saveNewPin"
                                        >
                                            Bestätigen
                                        </v-btn>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="account.role === 'parent'"
                            class="d-flex flex-row justify-space-between mt-2"
                        >
                            <div class="d-flex flex-row justify-space-around">
                                <p class="font-weight-bold">
                                    Bildrechte freigeben
                                </p>
                                <v-tooltip
                                    allow-overflow
                                    top
                                    max-width="400px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <img
                                            :src="infoIcon"
                                            class="icon pl-1"
                                            alt="Erklärung"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                    </template>
                                    <span>Erlauben Sie das Teilen von Fotos Ihres Kindes im Chat oder in der Klasse. Fotos können jederzeit auf Anfrage gelöscht werden.</span>
                                </v-tooltip>
                            </div>
                            <v-switch
                                v-model="pupilImageRestriction"
                                inset
                                hide-details
                                color="success"
                                style="margin-top: 0; padding-top: 0; margin-bottom:1.5%"
                            />
                        </div>
                        <div
                            class="d-flex flex-column"
                        >
                            <div class="d-flex flex-row justify-space-between">
                                <div 
                                    class="d-flex flex-row justify-space-around"
                                >
                                    <p class="font-weight-bold">
                                        Datenschutz
                                    </p>
                                    <v-tooltip
                                        allow-overflow
                                        top
                                        max-width="400px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <img
                                                :src="infoIcon"
                                                class="icon pl-1"
                                                alt="Erklärung"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                        </template>
                                        <span>Sie können hier die ihre Zustimmung zur Nutzungserklärung und Datenschutzinformation widerrufen.</span>
                                    </v-tooltip>
                                </div>
                                <v-switch
                                    v-model="usageAgreement"
                                    inset
                                    hide-details
                                    color="success"
                                    style="margin-top: 0; padding-top: 0; margin-bottom:1.5%"
                                />
                            </div>
                        </div>
                        <!--#region Censored expression -->
                        <div
                            v-if="account.role === 'teacher' || account.role === 'maintainer'"
                            class="d-flex flex-column "
                        >
                            <button
                                class="d-flex justify-space-between"
                                :class="!showExpressionInput ? 'mb-2':''"
                                style="cursor: pointer; width: 100%"
                                @click="showExpressionInput = !showExpressionInput"
                            >
                                <div class="d-flex justify-space-around">
                                    <div class="d-flex">
                                        <b>
                                            Zensurliste
                                        </b>
                                        <v-tooltip
                                            allow-overflow
                                            right
                                            max-width="400px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <img
                                                    :src="infoIcon"
                                                    class="icon pl-1"
                                                    alt="Erklärung"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                            </template>
                                            <span>Eingetragene Wörter werden bei der Übersetzung + Chat zensiert, beispielsweise Namen, personenbezogene Daten oder Schimpfwörter. Trennen Sie Wörter durch Kommata, z.B. 'Sonne, Mond, Sterne'.</span>
                                        </v-tooltip>
                                    </div>
                                </div>
                                <v-icon v-if="showExpressionInput">
                                    mdi-chevron-up
                                </v-icon>
                                <v-icon v-else>
                                    mdi-chevron-down
                                </v-icon>
                            </button>
                            <div class="d-flex mx-0">
                                <v-card
                                    v-if="showExpressionInput"
                                    v-click-outside="createCensoredExpression"
                                    elevation="0"
                                    class="d-flex flex-column"
                                    style="width: 100%"
                                >
                                    <v-textarea
                                        v-model="expressions"
                                        auto-grow
                                        placeholder="Eingetragene Wörter werden bei der Übersetzung + Chat zensiert, beispielsweise Namen, personenbezogene Daten oder Schimpfwörter. Trennen Sie Wörter durch Kommata, z.B. 'Sonne, Mond, Sterne'."
                                        dense
                                        outlined
                                        required
                                        hide-details
                                        class="my-2 px-2"
                                        style="border-radius: 8px"
                                    />
                                </v-card>
                            </div>
                        </div>
                        <div
                            class="d-flex flex-column "
                        >
                            <button
                                class="d-flex justify-space-between"
                                :class="!showLoginHistory ? 'mb-2':''"
                                style="cursor: pointer; width: 100%"
                                @click="clickOpenLoginHistory"
                            >
                                <div class="d-flex justify-space-around">
                                    <div class="d-flex">
                                        <b>
                                            Anmeldeverlauf
                                        </b>
                                        <v-tooltip
                                            allow-overflow
                                            right
                                            max-width="400px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <img
                                                    :src="infoIcon"
                                                    class="icon pl-1"
                                                    alt="Erklärung"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                            </template>
                                            <span>Übersicht über die letzten 10 An-/Abmeldungen dieses Nutzers.</span>
                                        </v-tooltip>
                                    </div>
                                </div>
                                <v-icon v-if="showLoginHistory">
                                    mdi-chevron-up
                                </v-icon>
                                <v-icon v-else>
                                    mdi-chevron-down
                                </v-icon>
                            </button>
                            <div
                                v-if="showLoginHistory" 
                                class="d-flex flex-column mx-0"
                            >
                                <v-container>
                                    <v-data-table
                                        :headers="headers"
                                        :items="shortHistory"
                                        :mobile-breakpoint="0"
                                        disable-pagination
                                        hide-default-footer
                                        fixed-header
                                        locale="de-DE"
                                        no-data-text="Keine Daten vorhanden"
                                    >
                                        <template v-slot:item="{ item }">
                                            <tr>
                                                <td>{{ item.type }}</td>
                                                <td>{{ formatDate(item.time) }}</td>
                                                <td>{{ item.device }}</td>
                                                <td>{{ item.browser }}</td>
                                                <td>{{ item.os }}</td>
                                            </tr>
                                        </template>
                                    </v-data-table>
                                </v-container>
                                <div
                                    v-if="showExportingOverlay"

                                    class="d-flex flex-column justify-space-around align-center"
                                >
                                    <v-progress-circular
                                        indeterminate
                                        size="50"
                                    />
                                    <p>
                                        PDF-Export wird vorbereitet, bitte gedulden Sie sich einen Moment
                                    </p>
                                </div>
                                <div class="d-flex flex-row justify-space-between align-center mt-2">
                                    <b class="mr-2">Gesamten Verlauf exportieren:</b>

                                    <v-btn
                                        class="backButton pointer buttonLength"
                                        style=" justify-content: center;"
                                        elevation="0"
                                        @click="exportLoginHistory"
                                    >
                                        <p
                                            style="text-transform: none !important"
                                        >
                                            Exportieren
                                        </p>
                                    </v-btn>
                                </div>
                                <!-- <v-overlay
                                    absolute
                                    :value="showExportingOverlay"
                                    opacity="0.8"
                                    style="width: 100% !important; z-index: 500 !important;"
                                > -->
                                
                                <!-- </v-overlay> -->
                            </div>
                        </div>
                        <!-- <div
                            v-if="accountRole === 'maintainer'"
                            class="d-flex justify-space-between pa-0 my-1 mx-0"
                        >
                            <b aria-hidden="true">
                                Google - Übersetzer
                            </b>

                            <v-tooltip
                                top
                                max-width="400px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <img
                                        :src="infoIcon"
                                        class="icon"
                                        alt="Erklärung"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                </template>
                                <span>Einige Sprachen wie zum Beispiel Arabisch(mit Bild hervorgehoben) sind nicht von DeepL unterstützt und verwenden daher den Google-Übersetzer.
                                    Wenn sie dies nicht möchten können sie dies hier ausschalten, jedoch sind die Sprachen dann nicht mehr auswählbar.</span>
                            </v-tooltip>
                            <v-spacer />
                            <v-switch
                                v-model="googleTranslate"
                                inset
                                aria-label="Google-übersetzer"
                                hide-details
                                class="ma-0 pa-0 pl-1 mb-1"
                                color="success"
                                @change="saveGoogleTranslate"
                            />
                        </div> -->
                        <div
                            v-if="accountRole === 'maintainer'"
                            class="d-flex justify-space-between pa-0 my-1 mx-0"
                        >
                            <b aria-hidden="true">
                                Chat - Zensur
                            </b>

                            <v-tooltip
                                top
                                max-width="400px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <img
                                        :src="infoIcon"
                                        class="icon"
                                        alt="Erklärung"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                </template>
                                <span>Um beim Chat die Sicherheit zu erhöhen, können Sie diese Option auswählen um zusätzlich personenbezogene Daten wie Namen, Email, Telefonnummer, Adresse etc. zu verschleiern.</span>
                            </v-tooltip>
                            <v-spacer />
                            <v-switch
                                v-model="censorChat"
                                inset
                                aria-label="Chat - Sicherheit"
                                hide-details
                                class="ma-0 pa-0 pl-1 mb-1"
                                color="success"
                                @change="saveChatCensor"
                            />
                        </div>
                    <!--#endregion -->
                    </div>
                    <!--#endregion -->
                </div>
                <div
                    style="display: flex; flex-direction: column; justify-content: center; align-items: flex-start"
                >
                    <!-- <v-btn
                        v-if="accountRole === 'maintainer'"
                        v-ripple
                        class="backButton pointer mb-2 buttonLength"
                        style="justify-content: left; margin-top: 20px"
                        elevation="0"
                        @click="()=>{ showFirstLoginDialog = true;}"
                    >
                        <img
                            :src="zahnrad"
                            class="icon20"
                        >
                        <p
                            class="ml-1 ma-0"
                            style="text-transform: none !important"
                        >
                            Ersteinrichtung
                        </p>
                    </v-btn> -->
                   

                    <v-btn
                        v-if="accountRole === 'maintainer'"
                        v-ripple
                        class="backButton pointer mb-2 buttonLength"
                        style="justify-content: left"
                        elevation="0"
                        @click="() => { changeYearDialog = true; $emit('extraDialog', true) }"
                    >
                        <v-icon
                            color="blau"
                            size="25"
                        >
                            mdi-swap-horizontal
                        </v-icon>
                        <p
                            class="ml-2 ma-0"
                            style="text-transform: none !important"
                        >
                            Jahreswechsel
                        </p>
                    </v-btn>
                    

                    <ChangeSchoolYear
                        v-if="changeYearDialog && accountRole === 'maintainer'"
                        :change-year-dialog="changeYearDialog"
                        @close="() => {changeYearDialog = false; $emit('extraDialog', false)}"
                    />

                    <v-btn
                        v-if="accountRole === 'maintainer'"
                        v-ripple
                        class="backButton pointer mb-2 buttonLength"
                        style="justify-content: left"
                        elevation="0"
                        @click="() => { newSchoolYearChange = true; $emit('extraDialog', true) }"
                    >
                        <v-icon
                            color="blau"
                            size="25"
                        >
                            mdi-swap-horizontal
                        </v-icon>
                        <p
                            class="ml-2 ma-0"
                            style="text-transform: none !important"
                        >
                            Excel-Upload
                        </p>
                    </v-btn>

                    <ExtendedExcelImport
                        v-if="newSchoolYearChange && accountRole === 'maintainer'"
                        :change-school-year="true"
                        @close="() => { newSchoolYearChange = false; $emit('extraDialog', false)}"
                    />
                    <v-btn
                        v-ripple
                        class=" pointer mt-6 buttonLength"
                        style="justify-content: left;background-color: var(--v-headerblue-base)"
                        elevation="0"
                        @click="() => { reloadPage() }"
                    >
                        <img
                            :src="aktualisierenIcon"
                            alt="Aktualisieren"
                            class="icon mr-2 iconToWhite"
                        >
                        <p
                            class="ml-2 ma-0"
                            style="text-transform: none !important; color:white;"
                        >
                            Neu Laden
                        </p>
                    </v-btn>
                    <v-btn
                        v-if="account.role === 'parent' || account.role === 'pupil' || account.role === 'teacher'"
                        v-ripple
                        class="backButton pointer mt-2 buttonLength"
                        style="justify-content: left"
                        elevation="0"
                        @click="() => { setSupportPageOpen(true); handleBackClick(true); }"
                    >
                        <img
                            :src="supportIcon"
                            class="icon mr-2"
                            alt=""
                        >
                        <p
                            class="ml-2 ma-0"
                            style="text-transform: none !important"
                        >
                            Erste-Hilfe
                        </p>
                    </v-btn>

                    <div class="logoutBtnPlacement mt-2">
                        <v-btn
                            class="backButton pointer mb-2 buttonLength"
                            color="red"
                            style="color: white; justify-content: left; padding: 0 7px;"
                            elevation="0"
                            @click="logout"
                        >
                            <img
                                :src="ausloggenIcon"
                                class="icon iconToWhite mr-2"
                                alt="Aus eklara abmelden"
                            >
                            <p
                                class="ma-0"
                                style="text-transform: none !important"
                            >
                                <b>Abmelden</b>
                            </p>
                        </v-btn>
                    </div>

                    <v-btn
                        class="visually-hidden"
                        @click="handleBackClick(true)"
                    >
                        Profil schließen
                    </v-btn>
                </div>
            </vue-custom-scrollbar>
            <p style="align-self: start !important; font-size: 12px;">
                v_2.0
            </p>
        </div>
        <div>
            <v-dialog
                v-model="downloadPdfDialog"
                persistent
                max-width="400"
                style="border-radius: 16px;"
            >
                <v-card style="border-radius: 16px;">
                    <v-card-title
                        class="headline"
                        style="background-color: var(--v-primary-base); color: white"
                    >
                        Accountänderung
                    </v-card-title>
                    <v-card-text>
                        <b>WICHTIG:</b> Laden Sie direkt die Zugangsdaten für die Nutzer
                        herunter. Dies ist nachträglich aus Datenschutzgründen nicht mehr
                        möglich. <br>
                        <br>
                        Sollten Sie in Zukunft ihr Passwort vergessen wenden Sie sich an die Verwaltung,
                        um ein neues Passwort zu erhalten.

                        <br>
                        <br>
                        <div>
                            <v-row
                                class="mt-5"
                                align="center"
                                justify="center"
                            >
                                <div style="width: 200px">
                                    <div
                                        style="width: 200px; gap: 4px"
                                        class="d-flex flex-column align-center ma-0"
                                    >
                                        <v-btn
                                            class="text-none"
                                            color="gruen"
                                            elevation="0"
                                            dark
                                            @click="account.role !== 'parent' ? pdf() : parentPdf()"
                                        >
                                            Download Zugangsdaten
                                        </v-btn>
                                    </div>
                                </div>
                            </v-row>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn
                            class="text-none"
                            elevation="0"
                            color="gruen"
                            dark
                            @click="handleFinish"
                        >
                            Fertig
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <SchoolInitialization
                :maintainer="meMaintainer"
                :show-first-login-dialog="showFirstLoginDialog"
                @closeFirstLogin="()=>{ showFirstLoginDialog = false}"
            />
            <ForceLogoutDialog
                :current-account="account._id"
                :show-force-logout-dialog="showForceLogoutDialog"
                :other-connected-devices="otherConnectedDevices"
                @closeForceLogout="(showSnackbar)=>{if(showSnackbar){ confirmForceLogout();} showForceLogoutDialog = false}"
                @confirmForceLogout="confirmForceLogout"
            />
            <v-dialog
                v-model="showSelectGroupDialog" 
                persistent 
                width="350"
            >
                <v-card style="border-radius: 8px;">
                    <v-card-title
                        style="background-color:#343e55; color: white"
                    >
                        Gruppe auswählen
                    </v-card-title>
                    <v-card-text class="mt-2">
                        Wählen sie die Gruppe, für die die Übersicht zur Datenschutzinformation exportiert werden soll
                        <div>
                            <v-select
                                v-model="selectedTeacherGroup"
                                hide-details
                                item-text="name"
                                :menu-props="{offsetY: true,}"
                                :items="groupsByLeader(userrole._id)"
                                class="ma-0 pa-0 m-2"
                                style="max-width: 87%"
                                return-object
                            />
                        </div>
                    </v-card-text>
                    <v-card-actions class="d-flex flex-row justify-space-between">
                        <v-btn
                            class="text-none"
                            color="#F5F5F5"
                            @click="closeExportDialog"
                        >
                            <img
                                :src="schließenIcon"
                                class="icon20 "
                                alt="abbrechen"
                            >
                            Abbrechen
                        </v-btn>
                        <v-btn
                            class="text-none"
                            color="gruen"
                            dark
                            @click="exportUsageAgreementForTeacherGroups(selectedTeacherGroup)"
                        >
                            <img
                                :src="checkIcon"
                                class="iconToWhite icon20"
                                alt="speichern"
                            >
                            Bestätigen
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog
                v-model="showConfirmPin"
                persistent
                max-width="350"
                min-height="200"
            >
                <v-card style="border-radius: 8px;">
                    <v-card-title
                        class="pa-2 d-flex justify-space-between"
                        style="background-color:#343e55; color: white"
                    >
                        Pin bestätigen
                        <img
                            :src="schließenIcon"
                            class="icon20 iconToWhite"
                            style="cursor: pointer"
                            alt="abbrechen"
                            @click="closeConfirmPin"
                        >
                    </v-card-title>
                    <div
                        style="background-color: white;"
                        class="pa-1 ma-0 d-flex flex-column justify-space-around align-center"
                    >
                        <!-- <div
                        class="d-flex flex-column align-center"
                    > -->
                        <v-otp-input
                            v-model="confirmPin"
                            type="password"
                            length="4"
                            style="max-width: 250px; max-height: 60px;"
                            class="custom-otp-input"
                            @finish="deactivateMaintainerPin"
                        />
                        <div style="position: relative !important; margin-top: -20px;">
                            <p class="mx-5">
                                PIN zum Bestätigen der Deaktivierung erforderlich
                            </p>
                        </div>
                    <!-- </div> -->
                    </div>
                </v-card>
            </v-dialog>
            <v-dialog
                v-model="showReloadDialog" 
                persistent 
                width="350"
            >
                <v-card style="border-radius: 8px;">
                    <v-card-title
                        style="background-color:#343e55; color: white"
                    >
                        Neuladen
                    </v-card-title>
                    <v-card-text
                        class="mt-2"
                    >
                        <p>Änderungen werden erst nach Neuladen der Seite aktiv. Automatisches Neuladen in {{ remainingReloadTime }} Sekunden</p>
                    </v-card-text>
                    <v-card-actions class="d-flex flex-row justify-space-between">
                        <v-btn
                            class="text-none"
                            color="#F5F5F5"
                            @click="abortReload"
                        >
                            <img
                                :src="schließenIcon"
                                class="icon20 "
                                alt="abbrechen"
                            >
                            Abbrechen
                        </v-btn>
                        <v-btn
                            class="text-none"
                            color="gruen"
                            dark
                            @click="reloadNow"
                        >
                            <img
                                :src="checkIcon"
                                class="iconToWhite icon20"
                                alt="speichern"
                            >
                            Jetzt Neuladen
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import { compress } from "shrink-string";
import * as backend from '@/api/backend';
import { PDFDocument, PageSizes, rgb} from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";

import roboto from "@/assets/Fonts/Roboto-Regular.ttf";
import robotoBold from "@/assets/Fonts/Roboto-Bold.ttf";
import blankPdf from "@/assets/PDF/blank.pdf";

import SchoolInitialization from '@/components/Verwaltung/schoolInitialization.vue';
import AvatarCreator from "@/components/SharedViews/Profile/AvatarCreator"
import ProgressBar from "@/components/ProgressBar";
import ChangeSchoolYear from "@/components/Verwaltung/Schuljahrwechsel/ChangeSchoolYear";
import JoinExternalButton from "@/components/JoinExternal/JoinExternalButton";
import JoinKlassenraumButton from "@/components/JoinKlassenraumButton";
import JoinLehrerzimmerButton from "@/components/Lehrer/JoinLehrerzimmerButton";
import JoinSprechzimmerButton from "@/components/JoinSprechzimmerButton";
import vueCustomScrollbar from "vue-custom-scrollbar";

import deFlagIcon from "@/assets/Icons/deutsch-59.svg";
import enFlagIcon from "@/assets/Icons/englisch-61.svg";
import frFlagIcon from "@/assets/Icons/französisch-60.svg";
import itFlagIcon from "@/assets/Icons/flag-italy.svg";
import ruFlagIcon from "@/assets/Icons/russisch.svg";
import roFlagIcon from "@/assets/Icons/flag-romania.svg";
import trFlagIcon from "@/assets/Icons/türkisch-97.svg";
import arFlagIcon from "@/assets/Icons/syrisch-98.svg";
import kuFlagIcon from "@/assets/Icons/flag-kurdistan.svg";
import ukFlagIcon from "@/assets/Icons/flag-ukraine.svg";

import uploadIcon from '@/assets/Icons/hochladen-15.svg';
import infoIcon from '@/assets/Icons/FaceliftIcons/info_hinweis.svg';
import briefIcon from '@/assets/Icons/FaceliftIcons/elternbriefe_zu_eingang.svg';
import thumbIcon from '@/assets/Icons/FaceliftIcons/passwort_login_fingerabdruck.svg';
import helpIcon from '@/assets/Icons/FaceliftIcons/barrierefreiheit_haende.svg';
import benachrichtigungenIcon from '@/assets/Icons/FaceliftIcons/benachrichtigung_an.svg';
import zensurIcon from '@/assets/Icons/FaceliftIcons/zensur.svg';
import documentIcon from '@/assets/Icons/FaceliftIcons/ordner_offen.svg';
import ausloggenIcon from '@/assets/Icons/FaceliftIcons/login_eintreten_einloggen_ausloggen_verlassen.svg';
import schließenIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import ExtendedExcelImport from "@/components/Verwaltung/Excel/ExtendedExcelImport";
import supportIcon from '@/assets/Icons/FaceliftIcons/hilfe_support_telefon_eklara.svg';
import eyeIcon from "@/assets/Icons/eye.svg";
import eyeOffIcon from "@/assets/Icons/eye-off.svg";
import mailIcon from '@/assets/Icons/letter.svg';
import brettIcon from '../assets/Icons/redesignV3/blackboard.svg';
import kalenderIcon from "../assets/Icons/FaceliftIcons/kalender_datum_zeit.svg";
import appIcon from "@/assets/Icons/Redesign/grid_view_white_24dp.svg"
import abwesendIcon from "@/assets/Icons/FaceliftIcons/lehrer.svg";
import schuelerIcon from '@/assets/Icons/FaceliftIcons/nutzer_schueler_default.svg';
import clockIcon from '@/assets/Icons/FaceliftIcons/stundenplan_uhr.svg';
import chatIcon from '@/assets/Icons/FaceliftIcons/chat_spechblase.svg';
import cameraIcon from "@/assets/Icons/FaceliftIcons/kamera.svg";
import checkIcon from "@/assets/Icons/FaceliftIcons/haeckehn_versendet.svg";
import saveIcon from '@/assets/Icons/FaceliftIcons/speichern.svg';
import NutzungsordnungEltern from "@/assets/PDF/templates/Nutzungsordnung_eklara_Erziehungsberechtigte.pdf";
import NutzungsordnungSchueler from "@/assets/PDF/templates/Nutzungsordnung_eklara_SuS.pdf";
import NutzungsordnungLehrer from "@/assets/PDF/templates/Nutzungsordnung_eklara_LK.pdf";
import NutzungsordnungExterne from "@/assets/PDF/templates/Nutzungsordnung_eklara_Externe.pdf";
import NutzungsordnungAdmin from "@/assets/PDF/templates/Nutzungsordnung_eklara_Administratoren.pdf";
import DatenschutzEltern from "@/assets/PDF/templates/Datenschutzinformation_eklara_Erziehungsberechtigte.pdf";
import DatenschutzLehrer from "@/assets/PDF/templates/Datenschutzinformation_eklara_LK.pdf";
import DatenschutzSchueler from "@/assets/PDF/templates/Datenschutzinformation_eklara_SuS.pdf";
import DatenschutzExterne from "@/assets/PDF/templates/Datenschutzerklärung_eklara_Externe.pdf";
import DatenschutzAdmin from "@/assets/PDF/templates/Datenschutzerklärung_eklara_Administratoren.pdf";
import EinwilligungEltern from "@/assets/PDF/templates/Einwilligungserklärung_Erziehungsberechtigte.pdf";
import EinwilligungExterne from "@/assets/PDF/templates/Einwilligungserklärung_eklara_Externe.pdf";

// import EinwilligungLehrer from "@/assets/PDF/templates/Einwilligungserklärung_eklara_LuL.pdf";
// import EinwilligungSchueler from "@/assets/PDF/templates/Einwilligungserklärung_eklara_SuS.pdf";

import FragebogenDatenschutz from "@/assets/PDF/templates/eklara2_0_2301_Fragebogen_Checkliste_für_IT_Anbieter_DSGVO_07_2020.pdf";
import pinIcon from "@/assets/Icons/FaceliftIcons/pin.svg";
import bookIcon from "@/assets/Icons/FaceliftIcons/Klassebuch_appicon.svg";
import fileIcon from '@/assets/Icons/file-white.svg';
import ForceLogoutDialog from '@/components/Utils/ForceLogoutDialog.vue';


import einfacheAnsicht from "@/assets/einfache-Ansicht.svg";
import normaleAnsicht from "@/assets/normale-Ansicht.svg";
import zahnrad from '@/assets/Icons/FaceliftIcons/einstellungen_zahnrad.svg';
import aktualisierenIcon from "@/assets/Icons/FaceliftIcons/aktualisieren.svg";

import JSZip from "jszip";
import {saveAs} from "file-saver";

import LoginPdf from "@/util/loginPdf";

export default {
    name: "ProfileManagement",
//         directives: {
//     'numeric-only': {
//       // Directive definition
//       bind(el, binding, vnode) {
//         el.addEventListener('keydown', (e) => {
//           // Allow: backspace, delete, tab, escape, enter, and numbers
//           if ([46, 8, 9, 27, 13].includes(e.keyCode) ||
//               // Allow: Ctrl/cmd+A
//               (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
//               // Allow: Ctrl/cmd+C
//               (e.keyCode === 67 && (e.ctrlKey === true || e.metaKey === true)) ||
//               // Allow: Ctrl/cmd+X
//               (e.keyCode === 88 && (e.ctrlKey === true || e.metaKey === true)) ||
//               // Allow: home, end, left, right
//               (e.keyCode >= 35 && e.keyCode <= 39)) {
//                return;
//           }
//           // Ensure that it is a number and stop the keypress if not
//           if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
//             e.preventDefault();
//           }
//         });
//       }
//     }
//   },
    components: {
        ExtendedExcelImport,
        AvatarCreator,
        ProgressBar,
        ChangeSchoolYear,
        JoinExternalButton,
        JoinKlassenraumButton,
        JoinLehrerzimmerButton,
        JoinSprechzimmerButton,
        vueCustomScrollbar,
        SchoolInitialization,
        ForceLogoutDialog,
    },
    props: {
        currentChild: { required: false, type: Object },
    },
    data() {
        return {
            aktualisierenIcon,
            zahnrad,
            uploadIcon,
            infoIcon,
            briefIcon,
            thumbIcon,
            benachrichtigungenIcon,
            zensurIcon,
            documentIcon,
            ausloggenIcon,
            schließenIcon,
            helpIcon,
            supportIcon,
            eyeIcon,
            eyeOffIcon,
            mailIcon,
            brettIcon,
            kalenderIcon,
            appIcon,
            abwesendIcon,
            schuelerIcon,
            clockIcon,
            chatIcon,
            cameraIcon,
            checkIcon,
            saveIcon,
            pinIcon,
            bookIcon,
            fileIcon,

            //pdfs
            NutzungsordnungEltern,
            NutzungsordnungSchueler,
            NutzungsordnungLehrer,
            DatenschutzEltern ,
            DatenschutzLehrer,
            DatenschutzSchueler ,
            EinwilligungEltern ,
            EinwilligungExterne,
            NutzungsordnungExterne,
            DatenschutzExterne,
            NutzungsordnungAdmin,
            DatenschutzAdmin,
            FragebogenDatenschutz,
            // expandables
            showLoginHistory: false,
            showBriefkopf: false,
            showHelpers: false,
            showChangePassword: false,
            showChangePin: true,
            showNotificationArea: false,
            showPermissionsArea: false,
            showEmailBadge: false,
            showWidgetMaintainence: false,

            initialWidgetStatus: [],
            deactivatedBlackboard: false,
            deactivatedChat: false,
            deactivatedCalendar: false,
            deactivatedDiary: false,
            deactivatedApps : false,
            deactivatedPinboard: false,
            deactivatedMessages: false,
            deactivatedPlan: false,
            deactivatedFiles: false,

            usageAgreement: true,
            displayName: '',

            children: [],   // Contains the children of a parent account for selection in mobile view
            currentChildLocal: null,

            securityQuestions: [],          // holds the available security questions
            securityQuestion: null,         // is a security question selected in the dialog

            validNumber: true,
            telephoneRules: [ v => /^$|([ ]?(\d)[ ]?){9,12}/.test(v) || 'keine gültige Nummer' ],


            email: '',
            alternateEmail:'',
            telephone: '',
            emailNotificationSubstitution: false,
            emailNotificationBlackboard: false,
            emailNotificationParentalLetter: false,
            emailNotificationSicknote: false,
            emailNotificationCalendar: false,
            emailNotificationChat: false,
            emailNotificationPinboard: false,
            smsNotificationSubstitution: false,
            smsNotificationBlackboard: false,
            smsNotificationParentalLetter: false,
            smsNotificationSicknote: false,
            smsNotificationCalendar: false,

            smsNotificationChat: false,

            showPassword: false,
            showPasswordOld: false,
            showPasswordNew: false,
            showEmailPw: false,
            showTelephone: false,

            showDocuments: false,

            breakStart: null,
            breakEnd: null,

            startTimeMenu: false,
            endTimeMenu: false,
            changingNotificationBreak: false,

            oldPassword: '',
            newPassword: '',
            newPasswordRepeat: '',

            twoFactor: false,
            twoFactorMenu: false,
            twoFactorPassword: '',
            twoFactorOTP: '',
            twoFactorEmailIsVerified: false,
            is2FAverified: false,

            qrCodeData: {},

            changeYearDialog: false,
            newSchoolYearChange: false,

            changedSchoollogo: false,


            downloadPdfDialog: false,

            account: {},
            userrole: {},
            // accountHasPin: false,

            initialPin: null,
            initialPinValid: false,
            newPin: null,
            repeatNewPin: null,


            videoTutorialsOfMyRole: [],

            // Simple Editor Stuff
            simpleEditorValue: false,

            showExpressionInput: false,
            expressions: '',
            censoredExpressions: [],
            selectedExpression:'',

            schoolId: '',
            schoolName: '',
            schoolStreet: '',
            schoolPostal: '',
            schoolCity: '',
            schoolEmail: '',
            schoolPhone: '',
            // generalDataSecurityOfficer:'',
            principal:'',
            schoolDataSecurityOfficer:'',
            logo: null,
            logoPreview: null,      // the image selected for upload
            logoToPreview: null,    // the image that is saved in backend

            fileXmlHttpRequest: null,
            showAttachmentUploadProgress: false,
            attachmentUploadProgress: 0.0,

            isPasswordVerified: false,
            verifyPassword: '',
            lastCheckedPassword: '',

            currentSchool: null,

            settings: {
                suppressScrollY: false,
                suppressScrollX: true,
                wheelPropagation: false,
            },

            selectedLanguage: '',
            languages: [
                { text: 'Deutsch', countryCode: 'de', icon: deFlagIcon, alt: 'Deutsche Flagge' },
                { text: 'Englisch', countryCode: 'en', icon: enFlagIcon, alt: 'Englische Flagge' },
                { text: 'Französisch', countryCode: 'fr', icon: frFlagIcon, alt: 'Französische Flagge' },
                { text: 'Italienisch', countryCode: 'it', icon: itFlagIcon, alt: 'Italienische Flagge' },
                { text: 'Russisch', countryCode: 'ru', icon: ruFlagIcon, alt: 'Russische Flagge' },
                { text: 'Rumänisch', countryCode: 'ro', icon: roFlagIcon, alt: 'Rumänische Flagge' },
                { text: 'Türkisch', countryCode: 'tr', icon: trFlagIcon, alt: 'Türkische Flagge' },
                { text: 'Arabisch', countryCode: 'ar', icon: arFlagIcon, alt: 'Arabische Flagge' },
                // { text: 'Kurdisch', countryCode: 'ku', icon: kuFlagIcon, alt: 'Kurdische Flagge' },
                { text: 'Ukrainisch', countryCode: 'uk', icon: ukFlagIcon, alt: 'Ukrainische Flagge' },
            ],

            // Leave sehen and vorlesen next to each other.
            // Only when sehen is true you can interact with vorlesen/screenreader in UI
            // name is what the value is called in the DB and used for patch request
            accessibilities: [
                { text: 'Einfach', name: 'babyView', value: false, explanation: `<p>Aktiviert eine einfache Ansicht von eklara.</p>
                    <div class="d-flex-column align-center justify-center">
                    <p>Einfache Ansicht:</p>
                    <img style="height: 100px" src="`+einfacheAnsicht+`" alt="Vorschau einfache Ansicht">
                    <p>Normale Ansicht:</p>
                    <img style="height: 100px" src="`+normaleAnsicht+`" alt="Vorschau normale Ansicht">
                    </div>` },
                { text: 'Sehen', name: 'sehen', value: false, explanation: 'Aktiviert kontrastreichere Darstellung und die zusätzliche Option "Lupe".' },
                { text: 'Screenreader', name: 'screenreader', value: false, explanation: 'Optimiert die Seite für die Nutzung mit einer Vorlese-Anwendung.' },
                { text: 'Hören', name: 'hoeren', value: false, explanation: 'Aktiviert die Anzeige von Metacom-Symbolen.' },
                // Vorerst nicht anzeigen, weil keine andere Funktion als Tastatursteuerung -> siehe auch setA11y Methode
                // { text: 'Motorik', name: 'motorik', value: false, explanation: 'Aktiviert die zusätzliche Option zur Steuerung von eKlara mit der Tastatur.' },
            ],

            sehen: null,
            motorik: null,
            groupnames: [],

            saving: false,

            pupilImageRight: {},

            //storage management
            showStorageManagement: false,
            totalStorage: 0,
            usedStorage: 0,
            pupilCount: 0,
            teacherCount: 0,
            pupilStorage: 0,
            teacherStorage: 0,

            //imports
            blankPdf,
            roboto,
            robotoBold,

            showFirstLoginDialog: false,
            selectedTeacherGroup:[],
            showSelectGroupDialog: false,

            showAppsSection: true,

            googleTranslate: false,
            censorChat: false,
            otherConnectedDevices: 0,
            showForceLogoutDialog: false,

            pinActivatedMaintainer: false,
            showConfirmPin: false,
            confirmPin: null,

            showExportingOverlay: false,
            fullHistory: [],
            shortHistory:[],
            headers: [
                {
                text: 'Ereignis',
                value: 'type',
                },
                {
                text: 'Browser',
                value: 'browser',
                },
                {
                text: 'Betriebssystem',
                value: 'os',
                },
                {
                text: 'Gerät',
                value: 'device',
                },
                {
                text: 'Datum',
                value: 'time',
                },
            ],
            showReloadDialog: false,
            remainingReloadTime: 10,
            timer: null,

            breakInfo:'',
        }
    },
    computed: {
        ...mapGetters('deactivatedFeatures',['deactivatedFeatures']),
        ...mapGetters('auth', [ 'accountRole','accountHasPin','diaryPinActivated','messagesPinActivated' ]),
        ...mapGetters('videoTutorial', [ 'videoTutorials' ]),
        ...mapState('magnifier', ['magnifier']),
         ...mapState('snackbar',['snackbarMessage']),
        ...mapState('maintainer', ['advSigned']),
        ...mapState('profileManagement', [ 'profileManagement' ]),
        ...mapState('translation', [ 'targetLang' ]),
        ...mapState('util', [ 'keyboard', 'windowWidth' ]),
        ...mapGetters("groups", ["groupsById","groupsByLeader"]),
        ...mapGetters('avatar', ['getAvatarPickerOpen']),
        ...mapState('imageRights', ['imageRights']),
        ...mapState('maintainers', ['meMaintainer', 'maintainers']),
        ...mapGetters('imageRights',['imageRightsByGroup','imageRightsByPupilId']),
        ...mapGetters('accounts', ['accounts', 'accountsById']),
        ...mapGetters('pupils', ['pupilsByAccountId']),
        ...mapGetters('teachers', ['teachersByAccountId']),
        ...mapGetters('parents', ['parentsByAccountId' ,'parentsById']),


        // isSchoolInformationValid() {
        //    const requiredFields = [
        //         this.schoolName,
        //         this.schoolEmail,
        //         this.schoolPostal,
        //         this.schoolCity,
        //         this.schoolStreet,
        //         this.principal,
        //         // this.generalDataSecurityOfficer,
        //         this.schoolDataSecurityOfficer
        //     ];

        //     return requiredFields.every(field => field.trim() !== '');
        // },
        // filteredLanguages() {
        //     // Condition for excluding 'Arabisch'
        //     const excludeGoogle = 
        //         !this.maintainers[0].googleTranslateActivated ||
        //         (this.accountRole === 'pupil' && !this.account?.allowGoogleTranslate) ||
        //         (this.accountRole === 'parent' && !this.accountsById[this.currentChild?.account]?.allowGoogleTranslate);
            
        //     if (excludeGoogle) {
        //         return this.languages.filter(language => language.text !== 'Arabisch');
        //     } else {
        //         return this.languages;
        //     }
        // },
        isSchoolInformationChanged() {
            const school = this.currentSchool;

            return (
                this.schoolName !== school.name ||
                this.schoolEmail !== school.email ||
                this.schoolPostal !== school.postal ||
                this.schoolCity !== school.city ||
                this.schoolStreet !== school.street ||
                this.principal !== school.principal ||
                this.schoolDataSecurityOfficer !== school.schoolDataSecurityOfficer ||
                this.changedSchoollogo
            );
        },

        isEmailExisting() {
            return this.email && this.email !== "" && this.email.includes("@");
        },

        isPasswordChanged() {
            if(this.verifyPassword === '' || this.verifyPassword === this.lastCheckedPassword) {
                return false;
            }
            return true;
        },
        isEmailChanged() {
            if (this.email === this.account.email ) {
                return false;
            }

            return true;
        },
        isAlternateEmailChanged() {
            if (this.alternateEmail === this.account.alternateEmail ) {
                return false;
            }

            return true;
        },

        //Checks if the user entered new account information
        isAccountInformationChanged() {
            if (this.account.canChangePassword) {
                return this.oldPassword !== '' || this.newPassword !== '' || this.newPasswordRepeat !== ''
            }

            if (
                this.telephone === this.account.telephone &&
                this.oldPassword === '' &&
                this.newPassword === '' &&
                this.newPasswordRepeat === '' &&
                this.account.notifications &&
                this.emailNotificationSubstitution === this.account.notifications.notificationSubstitutionEmail &&
                this.emailNotificationBlackboard === this.account.notifications.notificationBlackboardEmail &&
                this.emailNotificationParentalLetter === this.account.notifications.notificationParentalLetterEmail &&
                this.emailNotificationSicknote === this.account.notifications.notificationSicknoteEmail &&
                this.emailNotificationCalendar === this.account.notifications.notificationCalendarEmail &&
                this.emailNotificationPinboard === this.account.notifications.notificationPinboardEmail &&
                this.emailNotificationChat === this.account.notifications.notificationChatEmail &&
                this.smsNotificationSubstitution === this.account.notifications.notificationSubstitutionSMS &&
                this.smsNotificationBlackboard === this.account.notifications.notificationBlackboardSMS &&
                this.smsNotificationSicknote === this.account.notifications.notificationSicknoteSMS &&
                this.smsNotificationParentalLetter === this.account.notifications.notificationParentalLetterSMS &&
                this.smsNotificationChat === this.account.notifications.notificationChatSMS &&
                this.smsNotificationCalendar === this.account.notifications.notificationCalendarSMS
            ) {
                return false;
            }

            return true;
        },
        // Checks if new PWs are equal & password is at least of length 8 and contains one letter and one number
        checkPw() {
            const pwCheck = new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})");

            if (this.newPassword.trim() === this.newPasswordRepeat.trim()) {
                return pwCheck.test(this.newPassword);
            }
            else {
                return false;
            }
        },
        pupilImageRestriction:{
             // Getter function
            get() {
                if(this.pupilImageRight){
                    return !this.pupilImageRight.restrictImage;
                }else{
                    return false;
                }
            },
            // Setter function
            set(value) {
                // Split the full name into first name and last name
                this.toggleImageRight(this.pupilImageRight._id)
            }

        },
        showInitBadge() {
            const emptyValues = [
                this.schoolName,
                this.schoolEmail,
                this.principal,
                this.schoolCity,
                this.schoolPhone,
                this.schoolPostal,
                this.schoolStreet,
                this.schoolDataSecurityOfficer
            ].filter(value => value === '');

            return emptyValues.length;
        }
    },
    watch: {
        async breakStart(newVal){
            if(newVal === null && this.breakEnd === null){
                await this.removeNotificationBreak();
            }
        },
         async breakEnd(newVal){
            if(newVal === null && this.breakStart === null){
                await this.removeNotificationBreak();
            }
        },
        selectedExpression(newVal, oldVal) {
            this.selectedExpression = newVal;
        },
        censoredExpressions(newVal, oldVal) {
            //
        },
        usageAgreement(newVal){
            if(newVal === false){
                this.openUsageAgreement();
            }
        },
        showDocuments(newVal){
            if(newVal === true && this.accountRole==='maintainer' && !this.advSigned){
                this.showSnackbar({
                    message:`Um eklara 2.0 wie gewohnt weiter nutzen zu können ist eine ADV-Vereinbarung notwendig.
                     So sieht es die Datenschutzbehörde vor. Vielen Dank.`,
                    color:'warning',
                    timeout: 10000,
                    })
            }
        },
        alternateEmail(newVal){
            if(newVal === null){
                this.alternateEmail = '';
            }
        },
        email(newVal){
            if(newVal === null){
                this.email = '';
            }
        }
    },
    async mounted() {
        this.account = await this.getCurrentAccount(true);
        if(this.account.role === 'maintainer'){
            await this.initWidgetMaintenance();
        }
        if (this.account.role !== 'maintainer') {
            this.securityQuestions = await this.getSecurityQuestions();
        }
        // if(this.account.role === 'teacher' || this.accountRole === 'maintainer'){
        //     const hasPinRes = await backend.accountHasPin(this.account._id);
        //     if(hasPinRes.status === 200){
        //         this.accountHasPin = true;
        //     }
        // }
        if(this.accountRole !== 'pupil'){
            await this.loadConnectedDevices();
        }

        switch (this.account.role) {
            case 'pupil':
                this.groupnames = this.account.groups.map((groupId)=>({
                    ...groupId,
                    name:this.groupsById[groupId].name
                }));
                this.userrole = await this.getMePupil();
                this.setA11y();
                break;
            case 'teacher':                
                this.userrole = await this.getMeTeacher();
                this.showEmailBadge = this.userrole.hideEmailBadge ? !this.userrole.hideEmailBadge : true,
                await this.setCensoredExpressions();
                break;
            case 'parent':
                this.userrole = await this.getMeParent();
                this.showEmailBadge = this.userrole.hideEmailBadge ? !this.userrole.hideEmailBadge : true,
                this.setA11y();
                for (let i = 0; i < this.userrole.pupils.length; i++) {
                    let item = await this.getPupil(this.userrole.pupils[i]);
                    this.children.push(item);
                }
                this.currentChildLocal = this.currentChild;
                this.pupilImageRight = this.imageRightsByPupilId[this.currentChild._id];

                break;
            case 'maintainer':
                this.userrole = await this.getMeMaintainer();
                this.googleTranslate = this.userrole.googleTranslateActivated;
                this.censorChat = this.userrole.censorChatActivated;
                this.showEmailBadge = this.userrole.hideEmailBadge ? !this.userrole.hideEmailBadge : true,
                this.pinActivatedMaintainer = this.diaryPinActivated;
                await this.getSchoolData();
                await this.setCensoredExpressions();
                break;
            default:
                console.warn('This user role is not supported');
                break;
        }
        this.getDisplayName();

        this.email = this.account.email;
        this.alternateEmail = this.account.alternateEmail ? this.account.alternateEmail : '';
        this.telephone = this.account.telephone;
        this.securityQuestions = this.account.securityQuestion;
        this.securityQuestion = this.account.securityQuestion;
        this.twoFactor = this.account.twoFactor ? this.account.twoFactor : false;
        this.twoFactorEmailIsVerified = this.twoFactor;
        this.is2FAverified = this.twoFactor
        this.$watch('twoFactor', this.twoFactorWatcher);


        if (this.userrole) {
            this.simpleEditorValue = this.userrole.simpleEditor || false;
        }

        this.videoTutorialsOfMyRole = await this.setVideoTutorialStates();

        if (this.account && this.account.notifications) {
            this.emailNotificationSubstitution = this.account.notifications.notificationSubstitutionEmail;
            this.emailNotificationBlackboard =  this.account.notifications.notificationBlackboardEmail;
            this.emailNotificationParentalLetter = this.account.notifications.notificationParentalLetterEmail;
            this.emailNotificationSicknote =  this.account.notifications.notificationSicknoteEmail;
            this.emailNotificationCalendar = this.account.notifications.notificationCalendarEmail;
            this.emailNotificationChat = this.account.notifications.notificationChatEmail;

            this.smsNotificationSubstitution = this.account.notifications.notificationSubstitutionSMS;
            this.smsNotificationBlackboard =   this.account.notifications.notificationBlackboardSMS;
            this.smsNotificationParentalLetter =  this.account.notifications.notificationParentalLetterSMS;
            this.smsNotificationSicknote =   this.account.notifications.notificationSicknoteSMS;
            this.smsNotificationCalendar = this.account.notifications.notificationCalendarSMS;

            this.smsNotificationChat = this.account.notifications.notificationChatSMS;

            this.emailNotificationPinboard = this.account.notifications.notificationPinboardEmail;

        }
        this.selectedLanguage = this.targetLang;
        this.languages.sort(this.alphabetically);

        if (this.account.startNotifying && this.account.endNotifying) {
            const startDate = new Date(this.account.startNotifying);
            const endDate = new Date(this.account.endNotifying);

            this.breakStart = ('0' + (startDate.getHours())).slice(-2) + ':' + ('0' + startDate.getMinutes()).slice(-2);
            this.breakEnd = ('0' + (endDate.getHours())).slice(-2) + ':' + ('0' + endDate.getMinutes()).slice(-2);
        }
    },
     beforeDestroy() {
        clearInterval(this.timer);
    },
    methods: {
        ...mapMutations('maintainer', ['setShowDialogGDPR']),
        ...mapMutations('snackbar', ['showSnackbar']),
        ...mapActions('deactivatedFeatures',['updateDeactivatedFeatures', 'fetchDeactivatedFeatures']),
        ...mapActions('auth', ['getCurrentAccount', 'logoutUser', 'getSecurityQuestions']),
        ...mapActions('avatar', ['setAvatarPickerOpen']),
        ...mapActions('magnifier', ['toggleMagnifier']),
        ...mapActions('parents', ['getMeParent', 'editParent']),
        ...mapActions('profileManagement', ['toggleProfileManagement']),
        ...mapActions('pupils', ['getMePupil', 'getPupil', 'editPupil']),
        ...mapActions('school', ['postSchool', 'getSchool', 'getSchoolLogo']),
        ...mapActions('teachers', ['getMeTeacher', 'editTeacher', 'editTeacherProfile']),
        ...mapActions("maintainers", ["getMeMaintainer", "editMaintainer"]),
        ...mapActions('translation', ['setTargetLang']),
        ...mapActions('util', ['toggleKeyboard']),
        ...mapActions('videoTutorial', [ 'getTutorialProperties', 'updateTutorialProperties', 'setPrivacyPolicyState', 'setForcePrivacyPolicyState', 'setPrivacyPolicyTutorialInfo' ]),
        ...mapMutations('support', ['setSupportPageOpen']),
        ...mapMutations('auth', ['setPreventLogout','setAccountHasPin','setPinActivated']),
        ...mapActions('accounts', ['patchAccount']),
        ...mapActions('imageRights', ['getImageRights', 'toggleImageRight']),
        ...mapActions("maintainers", ["getMaintainers"]),
        
        reloadPage() {
            this.$router.go();
        },
        formatDate(time){
            const dateObj = new Date(time);
            return dateObj.toLocaleDateString('de-DE', {
                                                                day: '2-digit',
                                                                month: '2-digit',
                                                                year: '2-digit'
                                                            }) + ' ' +
                        dateObj.toLocaleTimeString('de-DE', {
                                                                hour: '2-digit',
                                                                minute: '2-digit'
                                                            });
        },
        async clickOpenLoginHistory(){
            this.showLoginHistory = !this.showLoginHistory;
            const res = await backend.getLoginHistory(this.account._id);
            const complete = await res.json();
            this.fullHistory = complete;
            const last10Entries = complete.reverse();
            this.shortHistory = last10Entries.slice(0,10);
        },
        async exportLoginHistory(){
        this.showExportingOverlay = true;
        const history = this.fullHistory;
            //array for testing
            //  const x4Array = [...history,...history,...history,...history, ...history, ...history,...history,...history]

            const existingPdfBytes = await fetch(this.blankPdf).then((res) =>
                res.arrayBuffer()
            );
            const pdfDoc = await PDFDocument.load(existingPdfBytes);
                
            pdfDoc.setTitle(`Anmeldeverlauf_${this.displayName}`)
                pdfDoc.registerFontkit(fontkit);

                const robotoBytes = await fetch(this.roboto).then((res) =>
                    res.arrayBuffer()
                );
                const robotoBoldBytes = await fetch(robotoBold).then((res) =>
                    res.arrayBuffer()
                );

                const robotoFont = await pdfDoc.embedFont(robotoBytes);
                const robotoBoldFont = await pdfDoc.embedFont(robotoBoldBytes);

                const fontSizeHeader = 12;
                const fontSize = 10;
                const fontHeight = robotoFont.heightAtSize(fontSizeHeader);
                const fontHeightNormal = robotoFont.heightAtSize(fontSize);
                const padding = 1;
                // Get the first page of the document
                const pages = pdfDoc.getPages();
                const firstPage = pages[0];

                let currentPage = firstPage;
                 let totalIndex = 0;
                 let pageNumberAdded = false;
                const metaData = {
                    title: { x: 10, y: currentPage.getHeight()  - fontHeight },
                    type: { x: 10, y: currentPage.getHeight()  - fontHeight - padding},
                    browser: { x: 10, y: currentPage.getHeight()  - fontHeight - padding },
                    os: { x: 10, y: currentPage.getHeight() - fontHeight - padding },
                    device: { x: 10, y: currentPage.getHeight()-  fontHeight - padding },
                    time: { x: 10, y: currentPage.getHeight()-  fontHeight - padding },
                };
                const diaryNoteTitle = `Anmeldeverlauf ${this.displayName}`;
                currentPage.drawText(diaryNoteTitle, {
                    x: metaData.title.x,
                    y: metaData.title.y -8, // to account for padding and Border
                    size: fontSizeHeader,
                    font: robotoBoldFont,
                    color: rgb(0.1, 0.1, 0.1)
                });
                currentPage.drawText('Ereignis', {
                    x: metaData.type.x,
                    y: metaData.type.y -22, // to account for padding and Border
                    size: fontSizeHeader,
                    font: robotoBoldFont,
                    color: rgb(0.1, 0.1, 0.1)
                });
                metaData.browser.x = metaData.type.x + robotoBoldFont.widthOfTextAtSize('Ereignis', fontSizeHeader)*2;
                currentPage.drawText('Browser', {
                    x: metaData.browser.x,
                    y: metaData.browser.y -22, // to account for padding and Border
                    size: fontSizeHeader,
                    font: robotoBoldFont,
                    color: rgb(0.1, 0.1, 0.1)
                });
                metaData.os.x = metaData.browser.x + robotoBoldFont.widthOfTextAtSize('Browser', fontSizeHeader)*2.5 ;
                currentPage.drawText('Betriebssystem', {
                    x: metaData.os.x +15,
                    y: metaData.os.y -22, // to account for padding and Border
                    size: fontSizeHeader,
                    font: robotoBoldFont,
                    color: rgb(0.1, 0.1, 0.1)
                });
                metaData.device.x = metaData.os.x + robotoBoldFont.widthOfTextAtSize('Betriebssystem', fontSizeHeader)*1.5;
                currentPage.drawText('Gerät', {
                    x: metaData.device.x + 40,
                    y: metaData.device.y -22, // to account for padding and Border
                    size: fontSizeHeader,
                    font: robotoBoldFont,
                    color: rgb(0.1, 0.1, 0.1)
                });
                metaData.time.x = metaData.device.x + robotoBoldFont.widthOfTextAtSize('Gerät', fontSizeHeader)*4;
                currentPage.drawText('Datum/Uhrzeit', {
                    x: metaData.time.x,
                    y: metaData.time.y -22, // to account for padding and Border
                    size: fontSizeHeader,
                    font: robotoBoldFont,
                    color: rgb(0.1, 0.1, 0.1)
                });

                 let linePosY = (fontHeight + padding) + 1* padding + fontHeightNormal + 8;

                // await Promise.all(x4Array.map(async (set) => {
                await Promise.all(history.map(async (set) => {
                totalIndex +=1;
                if(totalIndex % 26 === 0){
                    currentPage = pdfDoc.addPage(PageSizes.A4);
                    // const diaryNoteTitle = '';
                    // currentPage.drawText(diaryNoteTitle, {
                    //     x: metaData.title.x,
                    //     y: metaData.title.y -8, // to account for padding and Border
                    //     size: fontSizeHeader,
                    //     font: robotoBoldFont,
                    //     color: rgb(0.1, 0.1, 0.1)
                    // });
                    currentPage.drawText('Ereignis', {
                    x: metaData.type.x,
                    y: metaData.type.y-8, // to account for padding and Border
                    size: fontSizeHeader,
                    font: robotoBoldFont,
                    color: rgb(0.1, 0.1, 0.1)
                });
                metaData.browser.x = metaData.type.x + robotoBoldFont.widthOfTextAtSize('Ereignis', fontSizeHeader)*2;
                currentPage.drawText('Browser', {
                    x: metaData.browser.x,
                    y: metaData.browser.y -8, // to account for padding and Border
                    size: fontSizeHeader,
                    font: robotoBoldFont,
                    color: rgb(0.1, 0.1, 0.1)
                });
                metaData.os.x = metaData.browser.x + robotoBoldFont.widthOfTextAtSize('Browser', fontSizeHeader)*2.5 ;
                currentPage.drawText('Betriebssystem', {
                    x: metaData.os.x +15,
                    y: metaData.os.y -8, // to account for padding and Border
                    size: fontSizeHeader,
                    font: robotoBoldFont,
                    color: rgb(0.1, 0.1, 0.1)
                });
                metaData.device.x = metaData.os.x + robotoBoldFont.widthOfTextAtSize('Betriebssystem', fontSizeHeader)*1.5;
                currentPage.drawText('Gerät', {
                    x: metaData.device.x + 40,
                    y: metaData.device.y -8, // to account for padding and Border
                    size: fontSizeHeader,
                    font: robotoBoldFont,
                    color: rgb(0.1, 0.1, 0.1)
                });
                metaData.time.x = metaData.device.x + robotoBoldFont.widthOfTextAtSize('Gerät', fontSizeHeader)*4;
                currentPage.drawText('Datum/Uhrzeit', {
                    x: metaData.time.x,
                    y: metaData.time.y -8, // to account for padding and Border
                    size: fontSizeHeader,
                    font: robotoBoldFont,
                    color: rgb(0.1, 0.1, 0.1)
                });
                    linePosY = 20;
                }
                const time = new Date(set.time);
                const dateTime = 'am ' + time.toLocaleDateString('de-DE', {
                                                            day: '2-digit',
                                                            month: '2-digit',
                                                            year: '2-digit'
                                                        }) + ' um ' +
                      time.toLocaleTimeString('de-DE', {
                                                            hour: '2-digit',
                                                            minute: '2-digit'
                                                        });
                        currentPage.drawText(set.type, {
                            x: metaData.type.x,
                            y: metaData.type.y - linePosY - 3, // to account for padding and Border
                            size: fontSize,
                            font: robotoFont,
                            color: rgb(0.1, 0.1, 0.1)
                        });
                        currentPage.drawText(set.browser, {
                            x: metaData.browser.x,
                            y: metaData.browser.y - linePosY - 3, // to account for padding and Border
                            size: fontSize,
                            font: robotoFont,
                            color: rgb(0.1, 0.1, 0.1)
                        });
                        currentPage.drawText(set.os, {
                            x: metaData.os.x + 15,
                            y: metaData.os.y - linePosY - 3, // to account for padding and Border
                            size: fontSize,
                            font: robotoFont,
                            color: rgb(0.1, 0.1, 0.1)
                        });
                        currentPage.drawText(set.device, {
                            x: metaData.device.x + 40,
                            y: metaData.device.y - linePosY - 3, // to account for padding and Border
                            size: fontSize,
                            font: robotoFont,
                            color: rgb(0.1, 0.1, 0.1)
                        });
                        currentPage.drawText(dateTime, {
                            x: metaData.time.x,
                            y: metaData.time.y - linePosY - 3, // to account for padding and Border
                            size: fontSize,
                            font: robotoFont,
                            color: rgb(0.1, 0.1, 0.1)
                        });
                        
                        //seperatrion lines
                        currentPage.drawLine({
                            start: {
                            x: metaData.type.x,
                            y: metaData.type.y - linePosY - 17,
                            },
                            end: {
                                x: metaData.time.x + 120,
                                y: metaData.time.y - linePosY - 17,
                            },
                            thickness: 0.5,
                            color: rgb(0, 0, 0)
                        })
                        linePosY = linePosY + 30
                        if((totalIndex-1) % 26 === 0){
                            currentPage.drawText(`${pdfDoc.getPages().length}`, {
                                x: currentPage.getSize().width - 10 - robotoFont.widthOfTextAtSize(`${pdfDoc.getPages().length}`, 10), // to account for padding
                                y: 10, // to account for padding and Border
                                size: 12,
                                font: robotoFont,
                                color: rgb(0.1, 0.1, 0.1)
                            });
                            pageNumberAdded = true;
                        }
                    }));
                    if(!pageNumberAdded)
                    {
                    currentPage.drawText(`${pdfDoc.getPages().length}`, {
                        x: currentPage.getSize().width - 10 - robotoFont.widthOfTextAtSize(`${pdfDoc.getPages().length}`, 10), // to account for padding
                        y: 10, // to account for padding and Border
                        size: 12,
                        font: currentPage.robotoFont,
                        color: rgb(0.1, 0.1, 0.1)
                    });
                    }
                const pdfBytes = await pdfDoc.save();
                const blob = new Blob([pdfBytes], {type: "application/pdf"});
                const download = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.setAttribute('download', `Anmeldeverlauf_${this.displayName}.pdf`);
                link.href = download;
                document.body.appendChild(link);
                this.showExportingOverlay = false;
                link.click();
                setTimeout(function () {
                link.remove();
                    window.URL.revokeObjectURL(download);
                }, 1000);
    },
    async deactivateMaintainerPin(){
        if(this.accountHasPin){
            const check = await backend.isPinValid(this.account._id,{pin: this.confirmPin});
                    if(check.status === 200){
                        const accountPatchRes = await backend.patchAccount(this.account._id, {pinActivated: false});
                        await this.setPinActivated(false); 
                        this.confirmPin = null;
                        this.showConfirmPin = false;
                    }else{
                        this.showSnackbar({message:'PIN ungültig. Zum Deaktivieren der PIN muss die bestehende PIN eingegeben werden. Versuchen Sie es erneut oder wenden sich an das eklara Team', color: 'error', timeout:20000});
                        this.confirmPin = null;
                        this.pinActivatedMaintainer = true;
                        this.showConfirmPin = false;
                    }
        }
       else{
          const accountPatchRes = await backend.patchAccount(this.account._id, {pinActivated: false});
                        await this.setPinActivated(false); 
       }
        
    },
    closeConfirmPin(){
            this.confirmPin = null;
            this.pinActivatedMaintainer = true;
            this.showConfirmPin = false;
    },
    async saveMaintainerPin(){
        if(!this.pinActivatedMaintainer){
            if(this.accountHasPin){
                this.showConfirmPin = true;
                this.pinActivatedMaintainer = true;
                return;
            }else{
                await this.deactivateMaintainerPin();
            }
           
        }else{
            if(this.pinActivatedMaintainer !== this.diaryPinActivated){
                        const accountPatchRes = await backend.patchAccount(this.account._id, {pinActivated: this.pinActivatedMaintainer});
                        await this.setPinActivated(true); 
        }
        }
       
    },
    async loadConnectedDevices(){
          const connectionsRes =  await backend.checkWebsocketConnections(this.account._id);
            const clientsObject = await connectionsRes.json();
            this.otherConnectedDevices = clientsObject.connected - 1;
    },
    async confirmForceLogout(){
            this.showForceLogoutDialog = false;
            this.showSnackbar({message:`Andere Geräte erfolgreich ausgeloggt.`, color:'success'})
            await this.loadConnectedDevices();
    },
    async saveNewPin(){
        const res = await backend.updateAccountPin(this.account._id, {pin: this.newPin});
        const newAcc = await res.json();
         if(res.status === 200){
            this.showSnackbar({message:'PIN erfolgreich geändert. Sie können den PIN nun  verwenden.', color:'success'});
            this.setAccountHasPin(true);
         }else{
            this.showSnackbar({message:'Beim Ändern der PIN ist etwas schief gelaufen. Bitte laden Sie die Seite neu. Falls das Problem bestehen bleibt, wenden Sie sich an den Support.', color:'error'});
         }
        this.newPin = null;
        this.repeatNewPin = null;
        this.initialPinValid = false;
    },
    async checkInitialPin(){
        const res = await backend.isPinValid(this.account._id,{pin: this.initialPin});
        if(res.status === 200){
            this.showSnackbar({message:'PIN gültig. Sie können nun eine neue Pin eingeben.', color:'success'});
            this.initialPin = null;
            this.initialPinValid = true;
        }else{
            this.showSnackbar({message:'PIN ungültig. Versuchen Sie es erneut oder wenden Sie sich an die Verwaltung.', color: 'error'});
            this.initialPin = null;

        }
    },
    async saveDeactivatedFeatures(){
        if(this.deactivatedBlackboard === this.initialWidgetStatus.blackboard ||
            this.deactivatedChat === this.initialWidgetStatus.chat ||
            this.deactivatedCalendar  === this.initialWidgetStatus.calendar||
            this.deactivatedDiary  === this.initialWidgetStatus.diary||
            this.deactivatedApps  === this.initialWidgetStatus.apps||
            this.deactivatedPinboard  === this.initialWidgetStatus.pinboard||
            this.deactivatedMessages  === this.initialWidgetStatus.messages||
            this.deactivatedPlan  === this.initialWidgetStatus.plan||
            this.deactivatedFiles  === this.initialWidgetStatus.files){


            // values need to be inverted since in backend is saved whether feature is deaktivated but in frontend we show activated features 
        const res = await backend.toggleDeactivateFeature(
            {
                blackboard: !this.deactivatedBlackboard,
                chat: !this.deactivatedChat,
                pinboard: ! this.deactivatedPinboard,
                calendar: !this.deactivatedCalendar,
                diary: !this.deactivatedDiary,
                plan: !this.deactivatedPlan,
                messages: !this.deactivatedMessages,
                apps: !this.deactivatedApps,
                files: !this.deactivatedFiles,
            });
            if(res.status === 200){
                this.showSnackbar({message: 'Änderungen erfolgreich gespeichert. Die Änderungen treten bei nächstem Neuladen der Seite in Kraft.', color: 'success'});
                this.initialWidgetStatus = await this.fetchDeactivatedFeatures(true);
            }else{
                this.showSnackbar({message: 'Beim Bearbeiten der aktivierten Funktionen ist ein unerwarteter Fehler aufgetreten. Bitte laden Sie die Seite neu!', color: 'error'})
            }
        }else{
            return;
        }
    },
    async initWidgetMaintenance(){
        this.initialWidgetStatus = await this.fetchDeactivatedFeatures();
        this.deactivatedBlackboard = !this.initialWidgetStatus.blackboard;
        this.deactivatedChat = !this.initialWidgetStatus.chat;
        this.deactivatedCalendar = !this.initialWidgetStatus.calendar;
        this.deactivatedDiary = !this.initialWidgetStatus.diary;
        this.deactivatedApps = !this.initialWidgetStatus.apps;
        this.deactivatedPinboard = !this.initialWidgetStatus.pinboard;
        this.deactivatedMessages = !this.initialWidgetStatus.messages;
        this.deactivatedPlan = !this.initialWidgetStatus.plan;
        this.deactivatedFiles = !this.initialWidgetStatus.files;
    },
    closeExportDialog(){
        this.showSelectGroupDialog = false; 
        this.selectedTeacherGroup = [];
    },
    async exportUsageAgreementForTeacherGroups(group){
        const pdfBytes = await this.exportUsageAgreementStats(group);
        if(pdfBytes !== 'emptyGroup'){
            const blob = new Blob([pdfBytes], {type: "application/pdf"});
            const download = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.setAttribute('download', `Nutzungerklärungen in ${group.name}.pdf`);
            link.href = download;
            document.body.appendChild(link);
            link.click();
            setTimeout(function () {
            link.remove();
                window.URL.revokeObjectURL(download);
            }, 1000);
        }else{
             this.showSnackbar({ message: "Diese Gruppe hat keine Mitglieder!", color: "warning" });
        }
            this.closeExportDialog();

        // const jsZip = new JSZip();
        // const groupsForAccounts = this.groupsByLeader(this.userrole._id);
        // const pdfs = await Promise.all(groupsForAccounts.map( async (group) => {
        //     const pdfBytes = await this.exportUsageAgreementStats(group);
        //     if(pdfBytes !== 'emptyGroup'){
        //         // jsZip.file(`Nutzungerklärungen in ${group.name}.pdf`, pdfBytes);
        //         // return pdfBytes;
        //                             const blob = new Blob([pdfBytes], {type: "application/pdf"});
        //             const download = URL.createObjectURL(blob);
        //             const link = document.createElement("a");
        //             link.setAttribute('download', `Nutzungerklärungen in ${group.name}.pdf`);
        //             link.href = download;
        //             document.body.appendChild(link);
        //             link.click();
        //             setTimeout(function () {
        //             link.remove();
        //                 window.URL.revokeObjectURL(download);
        //             }, 1000);
        //     }
        // }))
        // const content = await jsZip.generateAsync({type: 'blob'});
        // saveAs(content, 'Nutzungserklärungen pro Klasse.zip');
    },
    async downloadDatenschutzFiles(){
        const versionNumber = '_v1.0';
        const nutzungsordnungLehrer = {name:'Nutzungsordnung_LK_v1.0', path: this.NutzungsordnungLehrer} 
        const nutzungsordnungSchueler ={name:'Nutzungsordnung_SuS_v1.0', path: this.NutzungsordnungSchueler}
        const nutzungsordnungEltern = {name:'Nutzungsordnung_Erziehungsberechtigte_v1.0', path: this.NutzungsordnungEltern}
             const nutzungsordnungAdmin = {name:'Nutzungsordnung_Administratoren_v1.0', path: this.NutzungsordnungAdmin}
        const nutzungsordnungExterne = {name:'Nutzungsordnung_Externe_v1.0', path: this.NutzungsordnungExterne}
        const datenschutzLehrer = {name:'Datenschutzinformation_LK_v1.0', path: this.DatenschutzLehrer}
        const datenschutzSchueler = {name:'Datenschutzinformation_SuS_v1.0', path: this.DatenschutzSchueler}
        const datenschutzEltern = {name:'Datenschutzinformation_Erziehungsberechtigte_v1.0', path: this.DatenschutzEltern}
        const datenschutzAdmin = {name:'Datenschutzinformation_Administratoren_v1.0', path: this.DatenschutzAdmin}
        const datenschutzExterne = {name:'Datenschutzinformation_Externe_v1.0', path: this.DatenschutzExterne}
    
        // const einwilligungLehrer = {name:'Einwilligungserklärung_LK', path: this.EinwilligungLehrer}
        // const einwilligungSchueler = {name:'Einwilligungserklärung_SuS', path: this.EinwilligungSchueler}
        const einwilligungEltern  = {name:'Einwilligungserklärung_Erziehungsberechtigte_v1.0', path: this.EinwilligungEltern}
        const einwilligungExterne  = {name:'Einwilligungserklärung_Externe_v1.0', path: this.EinwilligungExterne}
        const fragebogenDatenschutz = {name:'Fragebogen_Checkliste_für_IT-Anbieter_DSGVO_v1.0', path: this.FragebogenDatenschutz}

        
        const filesToDownload = [
            nutzungsordnungEltern,
            nutzungsordnungSchueler,
            nutzungsordnungLehrer,
            nutzungsordnungAdmin,
            nutzungsordnungExterne,
            datenschutzEltern,
            datenschutzLehrer,
            datenschutzSchueler,
            datenschutzAdmin,
            datenschutzExterne,
            einwilligungEltern,
            einwilligungExterne,
            // einwilligungLehrer,
            // einwilligungSchueler,
            fragebogenDatenschutz,
            ]

        const jsZip = new JSZip();
        await Promise.all(filesToDownload.map(async (file) => {
            const bytes = await fetch(file.path).then(res => res.arrayBuffer());
            jsZip.file( file.name +'.pdf', bytes);
        }))
        const vtt_res = await backend.getVTTTemplate();
        const vtt = await vtt_res.blob();
        const fragebogen_res = await backend.getWordTemplate();
        const fragebogen = await fragebogen_res.blob();
        jsZip.file('Fragebogen_IT-Anwendungen_Schule_v1.0.docx',fragebogen);
        jsZip.file('Verzeichnis_Verarbeitungstätigkeiten_VTT_eklara_v1.0.docx',vtt);
        const rollenkonzept_res =  await backend.getRollenkonzeptDocx();
        const rollenkonzept = await rollenkonzept_res.blob();
        jsZip.file('Rollen_und_Berechtigungskonzept_eklara_v1.0.docx',rollenkonzept);

        if(this.advSigned){
            const adv_res = await backend.getAVVSigned();
            const adv = await adv_res.blob();
            jsZip.file('ADV_TOM_eklara_unterschrieben_v1.0.pdf',adv);
        }
        const content = await jsZip.generateAsync({ type: 'blob' });
        saveAs(content, 'Datenschutzpaket_v1.0.zip');

    },
    async exportUsageAgreementStats(groupObj){
        let group = groupObj !== undefined ? groupObj : false;
           let accountsToBeExported = this.accounts.filter(acc => acc.role !== 'maintainer' && acc.accountName !== 'PersonAlle' )
            const getAccountByRole = (role, account) => {
                let user = '';
                switch(role) {
                    case 'pupil':
                        user = this.pupilsByAccountId[account._id]
                        if(user){
                            return `${user?.name} ${user?.lastName}`
                        }
                        else{
                            return 'MISSNIGUSR'
                        }
                    case 'teacher':
                        user = this.teachersByAccountId[account._id]
                        if(user){
                        return `${user?.name} ${user?.lastName}`
                        }else{
                            return 'MISSNIGUSR'
                        }
                    case 'parent':
                        return account.displayName;
                }
            }
            if(group && group.participants.length === 0){
                return 'emptyGroup'
            }
            if(group){
                let parentAccounts = [];
                const participantAccounts = group.participants.map(participant=>{
                    if(participant.parent){
                        const parent = this.parentsById[participant.parent]
                        if(parent){
                            const parentAccount = this.accountsById[parent.account];
                            if(!parentAccounts.includes(parentAccount)){
                                 parentAccounts.push(parentAccount);
                            }
                        }
                    }
                     return this.accountsById[participant.account]
                });
                accountsToBeExported = [...participantAccounts, ...parentAccounts];
            }
            const unfiltered = await Promise.all(accountsToBeExported.map(async account => ({
                name: getAccountByRole(account.role, account),
                role: account.role === 'parent' ? 'Eltern' : account.role ==='teacher' ? 'Lehrkraft' : 'Schüler/-in',
                isSigned: account.signedUsageAgreement.isSigned !== undefined ? account.signedUsageAgreement.isSigned : false,
                dateSigned: account.signedUsageAgreement.dateSigned !== '' && typeof account.signedUsageAgreement === 'object' ? new Date(account.signedUsageAgreement.dateSigned) : '',
            })));
            const preparedAccounts = unfiltered.filter(user => user.name !== 'MISSNIGUSR');
            //array for testing
            // const x4Array = [...preparedAccounts,...preparedAccounts,...preparedAccounts,...preparedAccounts, ...preparedAccounts, ...preparedAccounts,...preparedAccounts,...preparedAccounts]

            const existingPdfBytes = await fetch(this.blankPdf).then((res) =>
                res.arrayBuffer()
            );
            const pdfDoc = await PDFDocument.load(existingPdfBytes);
                if(group){
                    pdfDoc.setTitle(`Datenschutzinformation_pro_Nutzer in ${group.name}`)
                }
                else{
                    pdfDoc.setTitle('Datenschutzinformation_pro_Nutzer')
                }
                pdfDoc.registerFontkit(fontkit);

                const robotoBytes = await fetch(this.roboto).then((res) =>
                    res.arrayBuffer()
                );
                const robotoBoldBytes = await fetch(robotoBold).then((res) =>
                    res.arrayBuffer()
                );

                const robotoFont = await pdfDoc.embedFont(robotoBytes);
                const robotoBoldFont = await pdfDoc.embedFont(robotoBoldBytes);

                const fontSizeHeader = 12;
                const fontSize = 10;
                const fontHeight = robotoFont.heightAtSize(fontSizeHeader);
                const fontHeightNormal = robotoFont.heightAtSize(fontSize);
                const padding = 1;
                // Get the first page of the document
                const pages = pdfDoc.getPages();
                const firstPage = pages[0];

                let currentPage = firstPage;
                 let totalIndex = 0;
                 let pageNumberAdded = false;
                const metaData = {
                    title: { x: 10, y: currentPage.getHeight()  - fontHeight },
                    names: { x: 10, y: currentPage.getHeight()  - 2.5 * fontHeight - padding },
                    role: { x: 10, y: currentPage.getHeight() - 2.5 * fontHeight - padding },
                    isSigned: { x: 10, y: currentPage.getHeight()- 2.5 * fontHeight - padding },
                    dateSigned: { x: 10, y: currentPage.getHeight() - 2.5 * fontHeight - padding },
                    
                };
                const diaryNoteTitle = group ? `Datenschutzinformation\u00B9 (eklara/ BigBlueButton) von Nutzern in ${group.name}` : 'Übersicht Datenschutzinformation\u00B9 (eklara/ BigBlueButton) von Nutzern';
                currentPage.drawText(diaryNoteTitle, {
                    x: metaData.title.x,
                    y: metaData.title.y -8, // to account for padding and Border
                    size: fontSizeHeader,
                    font: robotoBoldFont,
                    color: rgb(0.1, 0.1, 0.1)
                });
                currentPage.drawText('Name', {
                    x: metaData.names.x,
                    y: metaData.names.y-8, // to account for padding and Border
                    size: fontSizeHeader,
                    font: robotoBoldFont,
                    color: rgb(0.1, 0.1, 0.1)
                });
                metaData.role.x = metaData.names.x + robotoBoldFont.widthOfTextAtSize('Name', fontSizeHeader)*4;
                currentPage.drawText('Rolle', {
                    x: metaData.role.x,
                    y: metaData.role.y -8, // to account for padding and Border
                    size: fontSizeHeader,
                    font: robotoBoldFont,
                    color: rgb(0.1, 0.1, 0.1)
                });
                metaData.isSigned.x = metaData.role.x + robotoBoldFont.widthOfTextAtSize('Rolle', fontSizeHeader)*2 +20 ;
                
                currentPage.drawText('Nutzungserklärung', {
                    x: metaData.isSigned.x,
                    y: metaData.isSigned.y -8, // to account for padding and Border
                    size: fontSizeHeader,
                    font: robotoBoldFont,
                    color: rgb(0.1, 0.1, 0.1)
                });
                metaData.dateSigned.x = metaData.isSigned.x + robotoBoldFont.widthOfTextAtSize('Nutzungserklärung', fontSizeHeader)*1.5;
                currentPage.drawText('Datum', {
                    x: metaData.dateSigned.x + 50,
                    y: metaData.dateSigned.y -8, // to account for padding and Border
                    size: fontSizeHeader,
                    font: robotoBoldFont,
                    color: rgb(0.1, 0.1, 0.1)
                });
                 let linePosY = (fontHeight + padding) + 1* padding + fontHeightNormal;

                await Promise.all(preparedAccounts.map(async (account) => {
                totalIndex +=1;
                if(totalIndex % 22 === 0){
                    currentPage = pdfDoc.addPage(PageSizes.A4);
                    const diaryNoteTitle = group ? `Datenschutzinformation\u00B9 (eklara/ BigBlueButton) von Nutzern in ${group.name}` : 'Übersicht Datenschutzinformation\u00B9 (eklara/ BigBlueButton) von Nutzern';
                    currentPage.drawText(diaryNoteTitle, {
                        x: metaData.title.x,
                        y: metaData.title.y -8, // to account for padding and Border
                        size: fontSizeHeader,
                        font: robotoBoldFont,
                        color: rgb(0.1, 0.1, 0.1)
                    });
                     currentPage.drawText('Name', {
                    x: metaData.names.x,
                    y: metaData.names.y -8, // to account for padding and Border
                    size: fontSizeHeader,
                    font: robotoBoldFont,
                    color: rgb(0.1, 0.1, 0.1)
                });
                    metaData.role.x = metaData.names.x + robotoBoldFont.widthOfTextAtSize('Name', fontSizeHeader)*4;
                    currentPage.drawText('Rolle', {
                        x: metaData.role.x,
                        y: metaData.role.y -8, // to account for padding and Border
                        size: fontSizeHeader,
                        font: robotoBoldFont,
                        color: rgb(0.1, 0.1, 0.1)
                    });
                    metaData.isSigned.x = metaData.role.x + robotoBoldFont.widthOfTextAtSize('Rolle', fontSizeHeader)*2 +20 ;
                    currentPage.drawText('Nutzungserklärung', {
                        x: metaData.isSigned.x,
                        y: metaData.isSigned.y -8, // to account for padding and Border
                        size: fontSizeHeader,
                        font: robotoBoldFont,
                        color: rgb(0.1, 0.1, 0.1)
                    });
                    metaData.dateSigned.x = metaData.isSigned.x + robotoBoldFont.widthOfTextAtSize('Nutzungserklärung', fontSizeHeader)*1.5;
                    currentPage.drawText('Datum', {
                        x: metaData.dateSigned.x + 50,
                        y: metaData.dateSigned.y - 8, // to account for padding and Border
                        size: fontSizeHeader,
                        font: robotoBoldFont,
                        color: rgb(0.1, 0.1, 0.1)
                    });
                    linePosY = 20;
                }
                const dateTime = (account.dateSigned !== '') ? 'am ' + new Date(account.dateSigned).toLocaleDateString('de-DE', {
                                                            day: '2-digit',
                                                            month: '2-digit',
                                                            year: '2-digit'
                                                        }) + ' um ' +
                        account.dateSigned.toLocaleTimeString('de-DE', {
                                                            hour: '2-digit',
                                                            minute: '2-digit'
                                                        }) : '';
            
                        currentPage.drawText(account?.name, {
                            x: metaData.names.x,
                            y: metaData.names.y - linePosY - 6, // to account for padding and Border
                            size: fontSize,
                            font: account.role === 'Eltern' ? robotoBoldFont : robotoFont,
                            color: rgb(0.1, 0.1, 0.1)
                        });
                        currentPage.drawText(account.role, {
                            x: metaData.role.x,
                            y: metaData.role.y - linePosY - 6, // to account for padding and Border
                            size: fontSize,
                            font: robotoFont,
                            color: rgb(0.1, 0.1, 0.1)
                        });
                        currentPage.drawText(account.isSigned ? 'zugestimmt': (!account.isSigned && account.dateSigned !== '' ) ? 'abgelehnt' : 'nicht zugestimmt', {
                            x: metaData.isSigned.x + 15,
                            y: metaData.isSigned.y - linePosY - 6, // to account for padding and Border
                            size: fontSize,
                            font: robotoFont,
                            color: rgb(0.1, 0.1, 0.1)
                        });
                        currentPage.drawText(dateTime, {
                            x: metaData.dateSigned.x + 20,
                            y: metaData.dateSigned.y - linePosY - 6, // to account for padding and Border
                            size: fontSize,
                            font: robotoFont,
                            color: rgb(0.1, 0.1, 0.1)
                        });
                        
                        //seperatrion lines
                        currentPage.drawLine({
                            start: {
                            x: metaData.names.x,
                            y: metaData.names.y - linePosY - 20,
                            },
                            end: {
                                x: metaData.dateSigned.x + 200,
                                y: metaData.dateSigned.y - linePosY - 20,
                            },
                            thickness: 0.5,
                            color: rgb(0, 0, 0)
                        })
                        linePosY = linePosY + 35
                        if((totalIndex-1) % 22 === 0){
                            currentPage.drawText(`${pdfDoc.getPages().length}`, {
                                x: currentPage.getSize().width - 10 - robotoFont.widthOfTextAtSize(`${pdfDoc.getPages().length}`, 10), // to account for padding
                                y: 10, // to account for padding and Border
                                size: 12,
                                font: robotoFont,
                                color: rgb(0.1, 0.1, 0.1)
                            });
                             currentPage.drawText('\u00B9 Beinhaltet Datenschutzinformation, Nutzungserklärung und Einwilligungserklärung', {
                                x: 10, // to account for padding
                                y: 8, // to account for padding and Border
                                size: 10,
                                font: currentPage.robotoFont,
                                color: rgb(0.1, 0.1, 0.1)
                            });
                            pageNumberAdded = true;
                        }
                    }));
                    if(!pageNumberAdded)
                    currentPage.drawText(`${pdfDoc.getPages().length}`, {
                        x: currentPage.getSize().width - 10 - robotoFont.widthOfTextAtSize(`${pdfDoc.getPages().length}`, 10), // to account for padding
                        y: 10, // to account for padding and Border
                        size: 12,
                        font: currentPage.robotoFont,
                        color: rgb(0.1, 0.1, 0.1)
                    });
                     currentPage.drawText('\u00B9 Beinhaltet Datenschutzinformation, Nutzungserklärung und Einwilligungserklärung', {
                        x: 10, // to account for padding
                        y: 8, // to account for padding and Border
                        size: 10,
                        font: currentPage.robotoFont,
                        color: rgb(0.1, 0.1, 0.1)
                    });

                const pdfBytes = await pdfDoc.save();
                if(group){
                    return pdfBytes;
                }
                else{
                    const blob = new Blob([pdfBytes], {type: "application/pdf"});
                    const download = URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.setAttribute('download', 'Datenschutzinformation_pro_Nutzer');
                    link.href = download;
                    document.body.appendChild(link);
                    link.click();
                    setTimeout(function () {
                    link.remove();
                        window.URL.revokeObjectURL(download);
                    }, 1000);
                }
            },
        async exportUsageAgreementsExterne(groupObj){
           const externalsRes = await backend.getExternalAgreements();
           const externals = await externalsRes.json();
            const prepared = externals.map(external => ({
                name: external.name + ' ' + external.lastName,
                role:'Externer',
                isSigned: true,
                dateSigned: new Date(external.dateSigned) ,
            }));
            //array for testing
            // const x4Array = [...preparedAccounts,...preparedAccounts,...preparedAccounts,...preparedAccounts, ...preparedAccounts, ...preparedAccounts,...preparedAccounts,...preparedAccounts]

            const existingPdfBytes = await fetch(this.blankPdf).then((res) =>
                res.arrayBuffer()
            );
            const pdfDoc = await PDFDocument.load(existingPdfBytes);
                
                pdfDoc.setTitle('Datenschutzinformation_Externer_Nutzer')
                pdfDoc.registerFontkit(fontkit);

                const robotoBytes = await fetch(this.roboto).then((res) =>
                    res.arrayBuffer()
                );
                const robotoBoldBytes = await fetch(robotoBold).then((res) =>
                    res.arrayBuffer()
                );

                const robotoFont = await pdfDoc.embedFont(robotoBytes);
                const robotoBoldFont = await pdfDoc.embedFont(robotoBoldBytes);

                const fontSizeHeader = 12;
                const fontSize = 10;
                const fontHeight = robotoFont.heightAtSize(fontSizeHeader);
                const fontHeightNormal = robotoFont.heightAtSize(fontSize);
                const padding = 1;
                // Get the first page of the document
                const pages = pdfDoc.getPages();
                const firstPage = pages[0];

                let currentPage = firstPage;
                 let totalIndex = 0;
                 let pageNumberAdded = false;
                const metaData = {
                    title: { x: 10, y: currentPage.getHeight()  - fontHeight },
                    names: { x: 10, y: currentPage.getHeight()  - 2.5 * fontHeight - padding },
                    role: { x: 10, y: currentPage.getHeight() - 2.5 * fontHeight - padding },
                    isSigned: { x: 10, y: currentPage.getHeight()- 2.5 * fontHeight - padding },
                    dateSigned: { x: 10, y: currentPage.getHeight() - 2.5 * fontHeight - padding },
                    
                };
                const diaryNoteTitle = 'Übersicht Datenschutzinformation\u00B9 (eklara/ BigBlueButton) von Nutzern';
                currentPage.drawText(diaryNoteTitle, {
                    x: metaData.title.x,
                    y: metaData.title.y -8, // to account for padding and Border
                    size: fontSizeHeader,
                    font: robotoBoldFont,
                    color: rgb(0.1, 0.1, 0.1)
                });
                currentPage.drawText('Name', {
                    x: metaData.names.x,
                    y: metaData.names.y-8, // to account for padding and Border
                    size: fontSizeHeader,
                    font: robotoBoldFont,
                    color: rgb(0.1, 0.1, 0.1)
                });
                metaData.role.x = metaData.names.x + robotoBoldFont.widthOfTextAtSize('Name', fontSizeHeader)*4;
                currentPage.drawText('Rolle', {
                    x: metaData.role.x,
                    y: metaData.role.y -8, // to account for padding and Border
                    size: fontSizeHeader,
                    font: robotoBoldFont,
                    color: rgb(0.1, 0.1, 0.1)
                });
                metaData.isSigned.x = metaData.role.x + robotoBoldFont.widthOfTextAtSize('Rolle', fontSizeHeader)*2 +20 ;
                
                currentPage.drawText('Nutzungserklärung', {
                    x: metaData.isSigned.x,
                    y: metaData.isSigned.y -8, // to account for padding and Border
                    size: fontSizeHeader,
                    font: robotoBoldFont,
                    color: rgb(0.1, 0.1, 0.1)
                });
                metaData.dateSigned.x = metaData.isSigned.x + robotoBoldFont.widthOfTextAtSize('Nutzungserklärung', fontSizeHeader)*1.5;
                currentPage.drawText('Datum', {
                    x: metaData.dateSigned.x + 50,
                    y: metaData.dateSigned.y -8, // to account for padding and Border
                    size: fontSizeHeader,
                    font: robotoBoldFont,
                    color: rgb(0.1, 0.1, 0.1)
                });
                 let linePosY = (fontHeight + padding) + 1* padding + fontHeightNormal;

                await Promise.all(prepared.map(async (account) => {
                totalIndex +=1;
                if(totalIndex % 22 === 0){
                    currentPage = pdfDoc.addPage(PageSizes.A4);
                    const diaryNoteTitle ='Übersicht Datenschutzinformation\u00B9 (eklara/ BigBlueButton) von Nutzern';
                    currentPage.drawText(diaryNoteTitle, {
                        x: metaData.title.x,
                        y: metaData.title.y -8, // to account for padding and Border
                        size: fontSizeHeader,
                        font: robotoBoldFont,
                        color: rgb(0.1, 0.1, 0.1)
                    });
                     currentPage.drawText('Name', {
                    x: metaData.names.x,
                    y: metaData.names.y -8, // to account for padding and Border
                    size: fontSizeHeader,
                    font: robotoBoldFont,
                    color: rgb(0.1, 0.1, 0.1)
                });
                    metaData.role.x = metaData.names.x + robotoBoldFont.widthOfTextAtSize('Name', fontSizeHeader)*4;
                    currentPage.drawText('Rolle', {
                        x: metaData.role.x,
                        y: metaData.role.y -8, // to account for padding and Border
                        size: fontSizeHeader,
                        font: robotoBoldFont,
                        color: rgb(0.1, 0.1, 0.1)
                    });
                    metaData.isSigned.x = metaData.role.x + robotoBoldFont.widthOfTextAtSize('Rolle', fontSizeHeader)*2 +20 ;
                    currentPage.drawText('Nutzungserklärung', {
                        x: metaData.isSigned.x,
                        y: metaData.isSigned.y -8, // to account for padding and Border
                        size: fontSizeHeader,
                        font: robotoBoldFont,
                        color: rgb(0.1, 0.1, 0.1)
                    });
                    metaData.dateSigned.x = metaData.isSigned.x + robotoBoldFont.widthOfTextAtSize('Nutzungserklärung', fontSizeHeader)*1.5;
                    currentPage.drawText('Datum', {
                        x: metaData.dateSigned.x + 50,
                        y: metaData.dateSigned.y - 8, // to account for padding and Border
                        size: fontSizeHeader,
                        font: robotoBoldFont,
                        color: rgb(0.1, 0.1, 0.1)
                    });
                    linePosY = 20;
                }
                const dateTime = (account.dateSigned !== '') ? 'am ' + new Date(account.dateSigned).toLocaleDateString('de-DE', {
                                                            day: '2-digit',
                                                            month: '2-digit',
                                                            year: '2-digit'
                                                        }) + ' um ' +
                        account.dateSigned.toLocaleTimeString('de-DE', {
                                                            hour: '2-digit',
                                                            minute: '2-digit'
                                                        }) : '';
            
                        currentPage.drawText(account?.name, {
                            x: metaData.names.x,
                            y: metaData.names.y - linePosY - 6, // to account for padding and Border
                            size: fontSize,
                            font: account.role === 'Eltern' ? robotoBoldFont : robotoFont,
                            color: rgb(0.1, 0.1, 0.1)
                        });
                        currentPage.drawText(account.role, {
                            x: metaData.role.x,
                            y: metaData.role.y - linePosY - 6, // to account for padding and Border
                            size: fontSize,
                            font: robotoFont,
                            color: rgb(0.1, 0.1, 0.1)
                        });
                        currentPage.drawText(account.isSigned ? 'zugestimmt': (!account.isSigned && account.dateSigned !== '' ) ? 'abgelehnt' : 'nicht zugestimmt', {
                            x: metaData.isSigned.x + 15,
                            y: metaData.isSigned.y - linePosY - 6, // to account for padding and Border
                            size: fontSize,
                            font: robotoFont,
                            color: rgb(0.1, 0.1, 0.1)
                        });
                        currentPage.drawText(dateTime, {
                            x: metaData.dateSigned.x + 20,
                            y: metaData.dateSigned.y - linePosY - 6, // to account for padding and Border
                            size: fontSize,
                            font: robotoFont,
                            color: rgb(0.1, 0.1, 0.1)
                        });
                        
                        //seperatrion lines
                        currentPage.drawLine({
                            start: {
                            x: metaData.names.x,
                            y: metaData.names.y - linePosY - 20,
                            },
                            end: {
                                x: metaData.dateSigned.x + 200,
                                y: metaData.dateSigned.y - linePosY - 20,
                            },
                            thickness: 0.5,
                            color: rgb(0, 0, 0)
                        })
                        linePosY = linePosY + 35
                        if((totalIndex-1) % 22 === 0){
                            currentPage.drawText(`${pdfDoc.getPages().length}`, {
                                x: currentPage.getSize().width - 10 - robotoFont.widthOfTextAtSize(`${pdfDoc.getPages().length}`, 10), // to account for padding
                                y: 10, // to account for padding and Border
                                size: 12,
                                font: robotoFont,
                                color: rgb(0.1, 0.1, 0.1)
                            });
                             currentPage.drawText('\u00B9 Beinhaltet Datenschutzinformation, Nutzungserklärung und Einwilligungserklärung', {
                                x: 10, // to account for padding
                                y: 8, // to account for padding and Border
                                size: 10,
                                font: currentPage.robotoFont,
                                color: rgb(0.1, 0.1, 0.1)
                            });
                            pageNumberAdded = true;
                        }
                    }));
                    if(!pageNumberAdded)
                    currentPage.drawText(`${pdfDoc.getPages().length}`, {
                        x: currentPage.getSize().width - 10 - robotoFont.widthOfTextAtSize(`${pdfDoc.getPages().length}`, 10), // to account for padding
                        y: 10, // to account for padding and Border
                        size: 12,
                        font: currentPage.robotoFont,
                        color: rgb(0.1, 0.1, 0.1)
                    });
                     currentPage.drawText('\u00B9 Beinhaltet Datenschutzinformation, Nutzungserklärung und Einwilligungserklärung', {
                        x: 10, // to account for padding
                        y: 8, // to account for padding and Border
                        size: 10,
                        font: currentPage.robotoFont,
                        color: rgb(0.1, 0.1, 0.1)
                    });

                const pdfBytes = await pdfDoc.save();
                
                
                    const blob = new Blob([pdfBytes], {type: "application/pdf"});
                    const download = URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.setAttribute('download', 'Datenschutzinformation_Externer');
                    link.href = download;
                    document.body.appendChild(link);
                    link.click();
                    setTimeout(function () {
                    link.remove();
                        window.URL.revokeObjectURL(download);
                    }, 1000);
                
            },
        async openUsageAgreement(){
            this.$emit('openUsageAgreement',{crtAccount: this.account,});
        },
        activateNotificationsPerRole(){
            this.emailNotificationBlackboard = true;
            this.emailNotificationParentalLetter = true;
            this.emailNotificationCalendar = true;
            this.emailNotificationChat = true;
            this.emailNotificationPinboard = true;
            
            if(this.accountRole === 'teacher'){
                this.emailNotificationSicknote = true;
                this.emailNotificationSubstitution = true;
            }
            this.saveNotifications()
        },
        clickShowNotification(){
            this.showNotificationArea = !this.showNotificationArea;
            if(this.showEmailBadge && this.showNotificationArea){
                this.showSnackbar({message: 'Aktivieren Sie Email Pushbenachrichtigungen um auf dem neusten Stand zu bleiben! ' + 
                'Tragen sie dazu ihre Email ein und bestätigen diese mit ihrem eklara Passwort.', color: 'warning', timeout: 10000})
            }
        },
        async emailBadgeClicked(){
            if(this.showEmailBadge){
                if( this.accountRole==='teacher' ){
                    this.userrole = await this.editTeacher({
                                        _id: this.userrole._id,
                                        hideEmailBadge: true
                                        });
                }else if(this.accountRole === 'parent'){
                    this.userrole = await this.editParent({
                                                            _id: this.userrole._id,
                                                            hideEmailBadge: true
                                                            });
                }
                else if(this.accountRole === 'maintainer'){
                    this.userrole = await this.editMaintainer({
                                                            _id: this.userrole._id,
                                                            hideEmailBadge: true
                                                            });
                }
                this.$emit('hideEmailBadge');
                this.showEmailBadge = false;
            }
        },
        async saveNotifications() {
            const patchBody = {
                notifications: {
                    ...(this.email !== '' ? { notificationSubstitutionEmail:  this.emailNotificationSubstitution } : {}),
                    ...(this.email !== '' ? { notificationBlackboardEmail:  this.emailNotificationBlackboard } : {}),
                    ...(this.email !== '' ? { notificationParentalLetterEmail:  this.emailNotificationParentalLetter } : {}),
                    ...(this.email !== '' ? { notificationSicknoteEmail: this.emailNotificationSicknote } : {}),
                    ...(this.email !== '' ? { notificationPinboardEmail: this.emailNotificationPinboard } : {}),

                    notificationSubstitutionSMS: this.smsNotificationSubstitution,
                    notificationBlackboardSMS: this.smsNotificationBlackboard,
                    notificationParentalLetterSMS: this.smsNotificationParentalLetter,
                    notificationSicknoteSMS:  this.smsNotificationSicknote,
                    notificationCalendarSMS: this.smsNotificationCalendar,
                    notificationCalendarEmail: this.emailNotificationCalendar,
                    notificationChatEmail: this.emailNotificationChat,
                    notificationChatSMS: this.smsNotificationChat,
                },
            }
            var accountPatchRes = await backend.patchAccount(this.account._id, patchBody);

            if (accountPatchRes.status === 200) {
                this.showSnackbar({ message: "Änderungen erfolgreich gespeichert.", color: "success" });
                this.account = await this.getCurrentAccount(true);
            }
            else {
                this.showSnackbar({ message: "Etwas hat nicht funktioniert. Laden sie die Seite erneut.", color: "error" });
            }
        },

        async saveEmail() {
        
            if ((this.email.trim().length > 0 && this.email.length < 7) && (!this.email.includes('@') || !this.email.includes('.'))) {
                this.showSnackbar({
                    message: 'Die E-Mail erfüllt die Kriterien nicht. Ist die E-Mail-Adresse gültig?',
                    color: 'error'
                })
                return;
            }
                    const accountPatchBody = {
                        email: this.email,
                    }
                    try {
                        const accountPatchRes = await backend.patchAccount(this.account._id, accountPatchBody);
                        const acc = await accountPatchRes.json();
                        if(acc.email && acc.email !== this.email){
                            this.email = acc.email;
                        }
                    } catch (e) {
                        console.error(e);
                        this.showSnackbar({
                            message: 'Beim Ändern Ihrer E-Mail ist ein Fehler aufgetreten. Bitte erneut versuchen.',
                            color: 'error',
                        });
                    }
                    this.activateNotificationsPerRole();
                    await this.emailBadgeClicked()
                    this.account = await this.getCurrentAccount(true);
               
        },
        async saveAlternateEmail(){
            if ((this.alternateEmail.trim().length > 0 && this.alternateEmail.length < 7) && (!this.alternateEmail.includes('@') || !this.alternateEmail.includes('.'))) {
                this.showSnackbar({
                    message: 'Die zusätzliche E-Mail erfüllt die Kriterien nicht. Ist die E-Mail-Adresse gültig?',
                    color: 'error'
                })
                return;
            }
            const accountPatchBody = {
                        alternateEmail: this.alternateEmail,
                    }
                    try {
                        const accountPatchRes = await backend.patchAccount(this.account._id, accountPatchBody);
                        const acc = await accountPatchRes.json();
                         if(this.alternateEmail !==''){
                        this.showSnackbar({
                            message: 'Zusätzliche E-Mail wurde geändert und gespeichert.',
                            color: 'success'
                        })
                    }else{
                        this.showSnackbar({
                            message: 'Änderungen gespeichert.',
                            color: 'success'
                        })
                    }
                    this.account = await this.getCurrentAccount(true);
                     
                    } catch (e) {
                        console.error(e);
                        this.showSnackbar({
                            message: 'Beim Ändern der zusätzlichen E-Mail ist ein Fehler aufgetreten. Bitte erneut versuchen.',
                            color: 'error',
                        });
                    }
        },
        async saveNotificationBreak(component) {
            if (component === 'start') {
                this.$refs.startTimeMenu.save(this.breakStart);
            } else {
                this.$refs.endTimeMenu.save(this.breakEnd);
            }
            if ((this.breakStart !== null) && (this.breakEnd !== null) && this.breakStart !== this.breakEnd) {
                const startSplit = this.breakStart.split(':');
                const endSplit = this.breakEnd.split(':');
                const utcTimeStart = new Date(2023, 0, 1, startSplit[0], startSplit[1]);
                const utcTimeEnd = new Date(2023, 0, 1, endSplit[0], endSplit[1]);
                let payload = {
                    startNotifying: utcTimeStart.toUTCString(),
                    endNotifying: utcTimeEnd.toUTCString(),
                };

                this.patchAccount({
                    accountId: this.account._id,
                    payload
                });
            }
            else{
                this.breakInfo = 'Start und Endzeit dürfen nicht gleich sein!';
            }
        },
        async removeNotificationBreak(){
               const response = await this.patchAccount({
                                    accountId: this.account._id,
                                    payload:{
                                        startNotifying: '',
                                        endNotifying: ''
                                    }
                                });
                if(response.accountName){
                    this.showSnackbar({message:'Ruhezeit erfolgreich entfernt.',color:'success'});
                }
        },
        async saveMaintainerProfile() {
            if (!this.isSchoolInformationChanged) {
                return;
            }

            // if (!this.isSchoolInformationValid) {
            //     this.showSnackbar({
            //         message: 'Achten Sie bitte darauf, dass kein Feld im Briefkopf leer ist',
            //         color: 'error',
            //     })
            //     return;
            // }
            try {
                let data;

                if (this.$refs.fileInput &&
                    this.$refs.fileInput.files[0] &&
                    this.$refs.fileInput.files[0].type !== ('image/png' || 'image/jpeg' || 'image/jpg')) {
                    this.showSnackbar({message: 'Das Logo muss im png-Format hochgeladen werden.', color: 'error'});
                    return;
                }

                if (this.schoolId) {
                    data = {
                        id: this.schoolId,
                        name: this.schoolName,
                        street: this.schoolStreet,
                        postal: this.schoolPostal,
                        city: this.schoolCity,
                        email: this.schoolEmail,
                        phone: this.schoolPhone,
                        // generalDataSecurityOfficer: this.generalDataSecurityOfficer,
                        schoolDataSecurityOfficer: this.schoolDataSecurityOfficer,
                        principal: this.principal,
                    }
                } else {
                    data = {
                        name: this.schoolName,
                        street: this.schoolStreet,
                        postal: this.schoolPostal,
                        city: this.schoolCity,
                        email: this.schoolEmail,
                        phone: this.schoolPhone,
                        // generalDataSecurityOfficer: this.generalDataSecurityOfficer,
                        schoolDataSecurityOfficer: this.schoolDataSecurityOfficer,
                        principal: this.principal,
                    }
                }

                let res = await this.postSchool(data);
                const formData = new FormData();
                const schoolLogo = this.logo;

                if (schoolLogo) {
                    formData.append("file", schoolLogo, schoolLogo.name);

                    this.fileXmlHttpRequest = backend.postSchoolLogo(res._id, schoolLogo);

                    this.fileXmlHttpRequest.onerror = (e) => {
                        console.error("attachment upload error:", e);
                        this.showAttachmentUploadProgress = false;
                        this.fileXmlHttpRequest = null;
                        this.$refs.fileInput.value = "";
                    };

                    this.fileXmlHttpRequest.onabort = () => {
                        console.warn("attachment upload aborted");
                        this.showAttachmentUploadProgress = false;
                        this.fileXmlHttpRequest = null;
                        this.$refs.fileInput.value = "";
                    };

                    this.fileXmlHttpRequest.addEventListener("load", () => {
                        if (res._id && this.fileXmlHttpRequest.status === 201) {
                            this.showSnackbar({message: 'Daten erfolgreich hinterlegt.'});
                            this.getSchoolData();
                        } else if (this.fileXmlHttpRequest.status !== 201) {
                            this.showSnackbar({
                                message: 'Beim Hochladen des Logos ist ein Fehler aufgetreten.',
                                color: 'error'
                            });
                        } else {
                            this.showSnackbar({
                                message: 'Beim Hinterlegen der Daten ist ein Fehler aufgetreten.',
                                color: 'error'
                            });
                        }

                        this.showAttachmentUploadProgress = false;
                        this.fileXmlHttpRequest = null;
                        this.$refs.fileInput.value = "";
                    });

                    this.fileXmlHttpRequest.upload.addEventListener("progress", (e) => {
                        this.attachmentUploadProgress = (e.loaded / e.total) * 100;
                    });

                    this.attachmentUploadProgress = 0.0;
                    this.showAttachmentUploadProgress = true;
                    this.fileXmlHttpRequest.send(formData);
                }

                this.logoPreview = null;

                this.showSnackbar({
                    message: 'Briefkopf erfolgreich gespeichert!',
                    color: 'success',
                })
            } catch (err) {
                this.showSnackbar({
                    message: 'Es ist ein unerwarteter Fehler aufgetreten!',
                    color: 'error',
                });
                console.error(err);
            }

        },
        async saveGoogleTranslate(){
            if(this.accountRole === 'maintainer' && this.googleTranslate !== this.userrole.googleTranslateActivated){
                this.userrole = await this.editMaintainer({
                                                            _id: this.userrole._id,
                                                            googleTranslateActivated: this.googleTranslate,
                                                            });
            this.showSnackbar({ message: "Änderungen erfolgreich gespeichert.", color: "success" });
            }
        },
        async saveChatCensor(){
            if(this.accountRole === 'maintainer' && this.censorChat !== this.userrole.censorChat){
                this.userrole = await this.editMaintainer({
                                                            _id: this.userrole._id,
                                                            censorChatActivated: this.censorChat,
                                                            });
            this.showSnackbar({ message: "Änderungen erfolgreich gespeichert.", color: "success" });
            }
        },
        async saveProfile() {
            this.saving = true;
            if(!this.downloadPdfDialog) {
                if (!this.isAccountInformationChanged) {
                    return;
                }
                if (this.newPassword !== '') {
                    if (!this.checkPw) {
                        this.showSnackbar({
                            message: 'Passwort erfüllt Kriterien nicht. Achte darauf, dass es mind. 10 Zeichen lang ist und einen Buchstaben und/oder Zahl sowie mind. 1 Sonderzeichen(!#$%()*+,-./:;=?@[]_) enthält.',
                            color: 'warning'
                        })
                        return;
                    }
                }
                if (!/^$|([ ]?(\d)[ ]?){7,16}/.test(this.telephone)) {
                    this.telephone = this.account.telephone;
                }
                if (this.telephone) {
                    this.telephone.replaceAll(' ', '')
                }

                this.isPasswordVerified = true;

                this.qrCodeData = await this.generateQRCodeData();

                const accountPatchBody = {
                    ...(this.oldPassword !== '' ? { 'oldPassword' : this.oldPassword } : {}),
                    ...(this.newPassword !== '' ? { 'password' : this.newPassword } : {}),
                    ...(this.newPassword !== '' ? { 'qrPassword' : this.qrCodeData } : {}),

                    notifications: {
                        ...(this.email !== '' ? { notificationSubstitutionEmail:  this.emailNotificationSubstitution } : {}),
                        ...(this.email !== '' ? { notificationBlackboardEmail:  this.emailNotificationBlackboard } : {}),
                        ...(this.email !== '' ? { notificationParentalLetterEmail:  this.emailNotificationParentalLetter } : {}),
                        ...(this.email !== '' ? { notificationSicknoteEmail: this.emailNotificationSicknote } : {}),
                         ...(this.email !== '' ? { notificationPinboardEmail: this.emailNotificationPinboard } : {}),

                        notificationSubstitutionSMS: this.smsNotificationSubstitution,
                        notificationBlackboardSMS: this.smsNotificationBlackboard,
                        notificationParentalLetterSMS: this.smsNotificationParentalLetter,
                        notificationSicknoteSMS:  this.smsNotificationSicknote,
                        notificationCalendarSMS: this.smsNotificationCalendar,
                        notificationCalendarEmail: this.emailNotificationCalendar,
                        notificationChatEmail: this.emailNotificationChat,
                        notificationChatSMS: this.smsNotificationChat,
                    },
                    telephone: this.telephone,
                }
                if (accountPatchBody.oldPassword && accountPatchBody.password) {
                    // inform store we have initiated password change and prevent this device from being logged out
                    await this.setPreventLogout(true);
                }
                const accountPatchRes = await backend.patchAccount(this.account._id, accountPatchBody);

                const userRolePatchBody = {
                    _id: this.userrole._id,
                    simpleEditor: this.simpleEditorValue,
                }
                let updatedUser;


                if (this.account.role === 'teacher') {
                    updatedUser = await this.editTeacher(userRolePatchBody);
                }

                if (accountPatchRes.status === 200 && !Number.isInteger(updatedUser)) {
                    this.showSnackbar({ message: "Änderungen erfolgreich gespeichert.", color: "success" });
                    this.oldPassword = '';
                    this.newPasswordRepeat = '';
                    this.newPassword = '';
                    this.account = await this.getCurrentAccount(true);
                    if(this.accountRole !== 'maintainer'){
                        this.downloadPdfDialog = true;
                    }
                }
            else if (accountPatchRes.status === 403) {
                this.oldPassword = '';
                this.newPasswordRepeat = '';
                this.newPassword = '';
                this.showSnackbar({ message: "Das alte Passwort war inkorrekt. Falls sie dieses nicht mehr haben, müssen sie die Verwaltung um eine Passwortänderung bitten.", color: "error" });
            }
                else {
                    this.showSnackbar({ message: "Etwas hat nicht funktioniert. Laden sie die seite neu!", color: "error" });
                }
            }
            this.saving = false;
        },
        async changeLanguage() {
            if (await this.requestPrivacyPolicyTutorialInfo()) {
                this.setPrivacyPolicyState(true);
            }

            this.setTargetLang(this.selectedLanguage);
            this.setAvatarPickerOpen(false);
            let snackbarMsg = "Für eine optimale Nutzung der Übersetzungsfunktion verwenden Sie bitte den Microsoft Edge oder Google Chrome Browser";
            // if(this.selectedLanguage === 'ar'){
            //     snackbarMsg = "Für Arabisch wird der Google-Übersetzer verwendet. Für eine optimale Nutzung der Übersetzungsfunktion verwenden Sie bitte den Microsoft Edge oder Google Chrome Browser";
            // }
            this.showSnackbar({ message: snackbarMsg,
                color: "orange" });
                

        },

        async requestPrivacyPolicyTutorialInfo() {
            const today = new Date();
            const privacyPolicyTutorialInfo = await this.getTutorialProperties({
                videoTutorialFilename: 'privacyPolicyTutorial',
                privacyPolicy: true,
                nextShowDate: new Date(today.getFullYear(), today.getMonth() + 6, today.getDate(), today.getHours(), today.getMinutes(), today.getSeconds(), today.getMilliseconds()),
            })
            this.setPrivacyPolicyTutorialInfo(privacyPolicyTutorialInfo);
            if (privacyPolicyTutorialInfo.timesShownRemaining < 3) {
                this.setForcePrivacyPolicyState(false);
            }
            return (privacyPolicyTutorialInfo.timesShownRemaining > 0 && privacyPolicyTutorialInfo.seeAgain)
                || Date.parse(privacyPolicyTutorialInfo.nextShowDate) < Date.now();
        },

        // a11y is abbreviation for accessibility
        async toggleA11y(a11y) {
            // Deep copy, so that the Einfach toggle doesn't vanish in the UI
            let a11ys = JSON.parse(JSON.stringify(this.accessibilities));
            let data;

            if (a11y.text === 'Einfach') {
                let i = a11ys.findIndex((el) => el.text === "Einfach");
                let babyView = a11ys.splice(i, 1);

                // babyView could become null sometimes, so we fix it with this
                if (babyView[0].value !== true) {
                    babyView[0].value = false;
                }

                data = { name: a11y.name, value: babyView[0].value };
            }
            else {
                // Making sure value is either true or false, became null sometimes
                if (a11y.value !== true) {
                    a11y.value = false;
                }

                // Toggling off sehen while screen reader is true should turn both to off/false
                if (a11y.text === 'Sehen' && a11y.value === false) {
                    // Toggling off in UI
                    let i = this.accessibilities.findIndex((el) => el.text === "Screenreader");
                    this.accessibilities[i].value = false;

                    data = { name: 'screenreader', value: false };  // Setting to false in backend

                    let res = await backend.patchAccessibility(this.account._id, data);
                    if (res.status !== 200) {
                        this.showSnackbar({ message: "Änderung konnte nicht gespeichert werden. Versuche es später erneut.", color: "error" });
                        this.setA11y();
                    }
                }

                data = { name: a11y.name, value: a11y.value };
            }

            let res = await backend.patchAccessibility(this.account._id, data);
            if (res.status !== 200) {
                this.showSnackbar({ message: "Änderung konnte nicht gespeichert werden. Versuche es später erneut.", color: "error" });
                this.setA11y();
                return;
            }
            this.startReloadDialog();
            // this.showSnackbar({ message: "Änderung gespeichert. Damit die Änderung aktiv wird, musst du die Seite neu laden.", color: "success" });
        },
        startReloadDialog(){
            this.showReloadDialog = true;
            this.timer = setInterval(() => {
                this.remainingReloadTime--;
                if(this.remainingReloadTime <= 0 ){
                     this.reloadNow();
                }
            }, 1000);
        },
        async abortReload(){
            this.showReloadDialog = false;
            this.remainingReloadTime = 10;
            const res = await backend.patchAccessibility(this.account._id, {'babyView' : this.userrole.babyView});
        },
        reloadNow(){
            this.$router.go();
        },
        setA11y() {
            let index = this.accessibilities.findIndex((el) => el.text === "Sehen");
            this.accessibilities[index].value = this.userrole.accessibility["sehen"];
            if (this.accessibilities[index].value) {
                this.sehen = true;
            }

            index = this.accessibilities.findIndex((el) => el.text === "Hören");
            this.accessibilities[index].value = this.userrole.accessibility["hoeren"];

            // Vorerst nicht anzeigen, weil keine andere Funktion als Tastatursteuerung -> siehe auch accessibilities Array declaration
            // index = this.accessibilities.findIndex((el) => el.text === "Motorik");
            // this.accessibilities[index].value = this.userrole.accessibility["motorik"];
            // if (this.accessibilities[index].value) {
            //     this.motorik = true;
            // }

            index = this.accessibilities.findIndex((el) => el.text === "Screenreader");
            this.accessibilities[index].value = this.userrole.accessibility["screenreader"];

            index = this.accessibilities.findIndex((el) => el.text === "Einfach");
            this.accessibilities[index].value = this.userrole.babyView;
        },

        async toggleSimpleEditor() {
            const userRolePatchBody = {
                _id: this.userrole._id,
                simpleEditor: this.simpleEditorValue,
            }

            let updatedTeacher = await this.editTeacher(userRolePatchBody);

            if (updatedTeacher) {
                this.showSnackbar({ message: "Änderung gespeichert.", color: "success" });
            } else {
                this.showSnackbar({ message: "Beim Speichern ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut.", color: "error" });
            }
        },

        getDisplayName() {
            let person = this.userrole;
            if (person) {
                switch (this.account.role) {
                    case 'pupil':
                        this.displayName = person ? person.name + ' ' + person.lastName.slice(0, 1) + '.' : 'Schüler';
                        break;
                    case 'teacher':
                        this.displayName = person ? person.name.slice(0, 1) + '. ' + person.lastName : 'Lehrer';
                        break;
                    case 'parent':
                        this.displayName = person ? this.account.accountName : 'Eltern';
                        break;
                    case 'maintainer':
                        this.displayName = 'Verwaltung';
                        break;
                }
            } else {
                console.warn('Error in displayName');
                this.displayName = '';
            }
        },

        alphabetically(a, b) {
            let x = a.text.toLowerCase();
            let y = b.text.toLowerCase();
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
        },

        logout() {
            this.logoutUser();
            this.$router.push({ name: 'login'});
        },

        handleBackClick(button) {
            if(this.showSelectGroupDialog || (this.snackbarMessage !== '' && !button)){
                return;
            }else{
                if(this.showFirstLoginDialog){
                   return;
                }else{
                     if (this.changingNotificationBreak && (!this.breakStart || !this.breakEnd)) {
                            return;
                        } else if (this.breakStart && this.breakEnd && this.changingNotificationBreak) {
                            this.changingNotificationBreak = false;
                            return;
                        }
                        if (!this.saving) {
                            this.setAvatarPickerOpen(false);
                            this.$emit('closeDialog');
                        } else {
                            this.$emit('saving');
                        }
                }
                
            }
        },

        async setVideoTutorialStates() {
            const videoTutorialofMyRole = this.videoTutorials.filter(tutorial => {
                if (this.userrole.babyView) {
                    return tutorial.role === 'baby';
                } else {
                    return tutorial.role === this.accountRole;
                }
            })
            for (const el of videoTutorialofMyRole) {
                const properties = await this.getTutorialProperties({ videoTutorialFilename: el.filename });
                el.seeAgain = properties.seeAgain;
            }
            return videoTutorialofMyRole;
        },

        changeVideoTutorialSeeAgainState(filename, seeAgain) {
            this.updateTutorialProperties(
                {
                    videoTutorialFilename: filename,
                    timesShownRemaining: 3,
                    seeAgain: seeAgain,
                });
            if(seeAgain) {
                this.showSnackbar({ message: "Video Tutorial eingeschaltet.", color: "success" });
            } else {
                this.showSnackbar({ message: "Video Tutorial ausgeschaltet.", color: "success" });
            }
        },

        async generateQRCodeData() {
            // create a QR PW, it's different from regular one for security reasons
            // it's saved in the QR code as base64 and if user uses same PW on multiple websites this helps a bit
            let qrPassword = ''
            let allCharacters =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

            for (let i = 0; i < 16; i++) {
                qrPassword += allCharacters.charAt(
                    Math.floor(Math.random() * allCharacters.length)
                );
            }
            /* const compressedName = await compress(this.account.accountName);
            const compressedPW = await compress(qrPassword);
            const qrCodeData = JSON.stringify({
                name: compressedName,
                pw: compressedPW,
            }); */
            const qrCodeData = qrPassword;
            return qrCodeData;
        },

        async deactivate2FA() {
            //
            if(!this.twoFactorOTP && this.is2FAverified) {
                const res = await backend.getActivate2Fa(this.account._id);
                if (res.status === 202) {
                    this.twoFactorEmailIsVerified = true;
                    this.showSnackbar({
                        message: 'Wir haben Ihrer E-Mail einen Code geschickt, benutzen Sie bitte diesen um 2FA zu deaktivieren!',
                        color: 'success',
                    });
                }
            } else {
                this.twoFactorOTP.trim();
                const res = await backend.activate2FA(this.account._id, {
                    email: this.email,
                    password: this.twoFactorPassword,
                    otp: this.twoFactorOTP,
                    twoFactorEnabled: false,
                });

                if (res.status === 201) {
                    this.showSnackbar({
                        message: `Zwei-Faktor-Authentifizierung wurde erfolgreich deaktiviert!`,
                        color: 'success',
                    });
                    this.twoFactorMenu = false
                    this.twoFactorEmailIsVerified = false;
                    this.is2FAverified = false;
                    this.twoFactorPassword = '';
                    this.twoFactorOTP = '';
                }
            }
        },


        async activate2FA() {
            //Email already verified?
            if (this.twoFactorEmailIsVerified) {
                //Remove whitespaces
                this.twoFactorOTP.replace(/\s+/g,'');
                const res = await backend.activate2FA(this.account._id, {
                    email: this.email,
                    password: this.twoFactorPassword,
                    otp: this.twoFactorOTP,
                    twoFactorEnabled: true,
                });
                //if successful
                if (res.status === 201) {
                    this.showSnackbar({
                        message: `Zwei-Faktor-Authentifizierung wurde erfolgreich ${this.twoFactor ? 'aktiviert' : 'deaktiviert'}!`,
                        color: 'success',
                    });
                    //Reset textfields
                    this.twoFactorMenu = false
                    this.twoFactorEmailIsVerified = false;
                    this.is2FAverified = true;
                } else {
                    this.showSnackbar({
                        message: 'Die eingegeben Daten stimmen nicht überein.',
                        color: 'error',
                    })
                }
            } else {
                //Send verificationcode
                const res = await backend.getActivate2Fa(this.account._id);
                if(res.status === 202) {
                    this.twoFactorEmailIsVerified = true;
                    this.showSnackbar({
                        message: 'Email erfolgreich bestätigt! Bitte überprüfe dein Postfach für den Verifizierungscode!',
                        color: 'success',
                    });
                    this.twoFactorEmailIsVerified = true;
                }

            }
        },
        async getSchoolData() {
            let school = await this.getSchool();

            this.currentSchool = school;

            if (school) {
                this.schoolId = school._id;
                this.schoolName = school.name;
                this.schoolStreet = school.street;
                this.schoolPostal = school.postal;
                this.schoolCity = school.city;
                this.schoolPhone = school.phone;
                this.schoolEmail = school.email;
                // this.generalDataSecurityOfficer = school.generalDataSecurityOfficer;
                this.schoolDataSecurityOfficer = school.schoolDataSecurityOfficer;
                this.principal = school.principal;
            }

            if (school.logo) {
                this.logoToPreview = await this.getSchoolLogo({schoolId: school._id, schoolLogo: school.logo, update: true});
            }
        },

        clickElement(element) {
            document.getElementById(element).click();
        },

        fileInputChange() {
            let input = this.$refs.fileInput;
            this.logo = input.files[0];
            this.logoPreview = URL.createObjectURL(this.logo);
            this.changedSchoollogo = true;
        },

        handleFinish() {
            this.downloadPdfDialog = false;

            // logout user, because PW was changed
            // this.logoutUser();
            // this.$router.push({ name: 'login' });
        },

        async setCensoredExpressions() {
            const response = await backend.getCensoredExpressions();
            const expressionList = await response.json();
            if(expressionList.length > 0) {
                this.expressions = expressionList[0].expression;
                this.censoredExpressions = expressionList;
            } else {
                this.censoredExpressions = [];
            }

        },

        async createCensoredExpression() {
            //this needs to be update with store to improve performance
            const originalExpressions = this.censoredExpressions;
            let newExpression = this.expressions.trim();
            if (originalExpressions[0]) {
                if (!(originalExpressions[0].expression === newExpression) && newExpression !== '') {
                    const expressionId = originalExpressions[0]._id;
                    const response = await backend.updateExpression(expressionId, {expression: newExpression});
                    if (response.status === 200) {
                        this.censoredExpressions = [];
                        this.showSnackbar({ message: `Zensurliste erfolgreich bearbeitet.`, color: 'success'});
                        await this.setCensoredExpressions();
                    }
                }
                if(newExpression === ''){
                    await backend.deleteExpression(originalExpressions[0]._id)
                }
            }
            else if (newExpression.length > 0 && newExpression !== '') {
                await backend.postExpression({expression: newExpression});
                this.showSnackbar({ message: "Zensurliste wurde erstellt.", color: 'success'});
                this.censoredExpressions = [];
                await this.setCensoredExpressions();
            }
            this.setAvatarPickerOpen(false);

        },

        twoFactorWatcher() {

            this.twoFactorMenu = !this.twoFactorMenu;

            if(!this.twoFactor && this.twoFactorEmailIsVerified) {
                this.deactivate2FA();
            }
            /*
            this.showSnackbar({
                message: 'Zum Ändern der Zwei-Faktor-Authentifizierung wird eine Bestätigung benötigt. Bitte überprüfen Sie Ihr Postfach.',
                color: 'success',
            })
             */

        },
        async pdf() {
            const pdfBytes = await LoginPdf.getNormalLoginPdf({
                firstName: this.userrole.name,
                lastName: this.userrole.lastName,
                groupName: this.account.groups,
                accountName: this.account.accountName,
                password: this.newPassword,
                qrPassword:  this.qrCodeData,
                role: this.accountRole
            });
            this.saveByteArray(this.account.accountName + ".pdf", pdfBytes);
            this.oldPassword = '';
            this.newPasswordRepeat = '';
            this.newPassword = '';
        },

        async parentPdf() {
            const fullChildName = this.children[0].name + ' ' + this.children[0].lastName;
            const pdfBytes = await LoginPdf.getParentLoginPdfv2({
                fullChildName: fullChildName,
                accountName: this.account.accountName,
                password: this.newPassword,
                qrPassword:  this.qrCodeData,
            });
            this.saveByteArray('Eltern_' + fullChildName + ".pdf", pdfBytes);
            this.oldPassword = '';
            this.newPasswordRepeat = '';
            this.newPassword = '';
        },

        saveByteArray(reportName, byte) {
            let blob = new Blob([byte], { type: "application/pdf" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = reportName;
            link.click();
        },


        setSaving() {
            this.saving = true;
        },

        resetSaving() {
            this.saving = false;
        },
    },
}
</script>

<style>
/* Remove right margin on v-switches */
.v-application--is-ltr .v-input--selection-controls__input {
    margin-right: 0;
}
.custom-otp-input .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset{
  height: 34px; /* Adjust the height as needed */
}
.custom-otp-input .theme--light.v-text-field--outlined > .v-input__control > .v-input__slot fieldset{
  height: 36px; /* Adjust the height as needed */
}
.custom-otp-input .v-text-field input {
padding-bottom: 30px !important;
}
</style>

<style lang="scss" scoped>
::v-deep .v-label {
    color: rgba(0, 0, 0, 0.87) !important;
}

.storageListItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.helpDiv {
    background-color: pink;
    min-height: 50px;
    height: 50px;
    min-width: 50px;
    width: 50px;
    border-radius: 8px;
}

.inputField {
    max-width: 500px;
    background-color: white;
    border-radius: 8px;
}

.scroll-area {
    position: relative;
    margin: 0;
    max-height: calc(100vh - 74px - 48px);
}

#custom-disabled.v-btn--disabled {
    background-color: var(--v-fgrau-base) !important;
    color: white;
}

.switchRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
}

.header {
    margin-bottom: 0.5em;
}

.icon {
    height: 20px;
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.greyBox {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    padding: 20px;
}

.changePwBtn {
    border-radius: 10px;
    margin-left: 8px;
}

.backButton {
    color: var(--v-dunkelgrau-base);
    border-radius: 10px !important;
}

.actionBtn {
    color: white;
    border-radius: 8px;
    min-width: 30px !important;
    width: 30px !important;
    height: 30px !important;
}
.errorText {
    color: red;
}

@media only screen and (max-width: 500px) {
    #btnContainer {
        flex-direction: column;
    }

    .changePwBtn {
        order: 1;
        margin-left: 0;
    }

    .greyBox {
        padding: 0;
    }
}

@media only screen and (max-width: 1365px) and (min-width: 900px){
    .logoutBtnPlacement {
        flex-direction: column;
    }
}

.buttonLength {
    width: 160px !important;
}

.iconToPurple {
    filter: brightness(0) saturate(100%) invert(36%) sepia(55%) saturate(824%) hue-rotate(249deg) brightness(87%) contrast(73%);
}

</style>
